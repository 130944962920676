import React, { ReactNode, useState, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Avatar, Menu, MenuItem, Box, IconButton } from '@mui/material';
import { MonetizationOn } from '@mui/icons-material';
import { useAuth } from './AuthContext';
import { UserRole } from '../common/enums';
import { useScore } from './ScoreContext'; // Import useScore from ScoreContext

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { user, logout } = useAuth(); // Auth context providing user and logout functionality
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { score, refreshScore } = useScore(); // Use the score and refresh function from the context

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userRole = user?.role;

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: '#1976d2' }}>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Tutor Track
          </Typography>
          <Button color="inherit" component={Link} to="/">
            Etusivu
          </Button>          
          {userRole === UserRole.Admin && (
            <>
              <Button color="inherit" component={Link} to="/curriculum">
                Opinto-ohjelmat
              </Button>
              <Button color="inherit" component={Link} to="/course">
                Kurssit
              </Button>
            </>
          )}
          {userRole === UserRole.Tutor && (
            <Button color="inherit" component={Link} to="/course">
              Kurssit
            </Button>
          )}
          {userRole === UserRole.Student && (
            <>
              {user?.email === 'monika.ruotsalainen@gmail.com' && (
              <Button color="inherit" component={Link} to="/fixtext">
                Korjaus
              </Button>
              )}
              {/* Display the score only for students */}
              {score !== null && (
                <Box display="flex" alignItems="center" ml={2}>
                  <IconButton color="inherit" onClick={refreshScore} title="Refresh Score"> {/* Refresh button */}
                    <MonetizationOn />
                  </IconButton>
                  <Typography variant="subtitle1">
                    {score} pistettä
                  </Typography>
                </Box>
              )}
            </>
          )}
          {user ? (
            <>
              <Avatar alt={user?.name} src={user?.picture} style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={handleMenu} />
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <Typography variant="h6" style={{ marginLeft: '10px' }}>
                    {user?.name}
                  </Typography>
                </MenuItem>
                <MenuItem onClick={logout}>Kirjaudu ulos</MenuItem>
              </Menu>
            </>
          ) : (
            <Button color="inherit" component={Link} to="/login">
              Kirjaudu
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container>{children}</Container>
    </div>
  );
};

export default Layout;
