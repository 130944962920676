import React, { useState } from 'react';
import { Card, CardContent, Typography, CardActions, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Lesson } from '../../types/interfaces'; // Import the Lesson type
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Import an icon for active/inactive status

const LessonItem: React.FC<{ lesson: Lesson, onDelete: (id: number) => void }> = ({ lesson, onDelete }) => {
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    await onDelete(lesson.id);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Order Number */}
        <Typography variant="h3" component="div" sx={{ mr: 3 }}>
          {lesson.order}
        </Typography>

        {/* Lesson Title and Active Status */}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            {lesson.title}
          </Typography>
        </Box>

        {/* Active/Inactive Indicator */}
        <FiberManualRecordIcon 
          sx={{ color: lesson.isActive ? 'green' : 'gray' }} 
          titleAccess={lesson.isActive ? 'Aktiivinen' : 'Ei aktiivinen'}
        />
      </CardContent>
      <CardActions>
        <Button size="small" component={RouterLink} to={`/course/${lesson.courseId}/lesson/${lesson.id}`}>
          Näytä
        </Button>
        <Button size="small" component={RouterLink} to={`/course/${lesson.courseId}/lesson/edit/${lesson.id}`}>
          Muokkaa
        </Button>
        <Button size="small" color="secondary" onClick={handleClickOpen}>
          Poista
        </Button>
      </CardActions>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Vahvista poisto"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oletko varma että haluat poistaa oppitunnin "{lesson.title}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Peruuta
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Poista
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default LessonItem;
