import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Home from './pages/Home';
import { CurriculumsPage, CurriculumCreatePage, CurriculumEditPage, CurriculumViewPage } from './pages/Curriculum';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import GlobalStyle from './styles/GlobalStyle';
import Layout from './components/Layout';
import { AuthProvider } from './components/AuthContext';
import { ScoreProvider } from './components/ScoreContext'; // Import the ScoreProvider
import LoginPage from './pages/LoginPage';
import { CoursesPage, CourseCreatePage, CourseEditPage, CourseViewPage } from './pages/Course';
import { LessonCreatePage, LessonEditPage } from './pages/Lesson';
import { LoadingProvider, useLoading } from './components/LoadingContext';
import Loader from './components/Loader';
import { initializeLoadingState } from './services/api';
import LessonViewPage from './pages/Lesson/LessonViewPage';
import { ExerciseCreatePage, ExerciseEditPage, ExerciseViewPage } from './pages/Exercise/';
import AssignExercisePage from './pages/Assignment/Manage/AssignExercisePage';
import StudentAssignmentPage from './pages/Assignment/Student/StudentAssignmentPage';
import TextFixerPage from './pages/FixText';

const AppWithLoading: React.FC = () => {
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    initializeLoadingState(setLoading);
  }, [setLoading]);

  return (
    <>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/curriculum" element={<CurriculumsPage />} />
            <Route path="/curriculum/create" element={<CurriculumCreatePage />} />
            <Route path="/curriculum/edit/:id" element={<CurriculumEditPage />} />
            <Route path="/curriculum/:id" element={<CurriculumViewPage />} />

            <Route path="/course" element={<CoursesPage />} />
            <Route path="/course/create" element={<CourseCreatePage />} />
            <Route path="/course/edit/:id" element={<CourseEditPage />} />
            <Route path="/course/:id" element={<CourseViewPage />} />

            {/* Lesson Routes */}
            <Route path="/course/:courseId/lesson/create" element={<LessonCreatePage />} />
            <Route path="/course/:courseId/lesson/edit/:lessonId" element={<LessonEditPage />} />
            <Route path="/course/:courseId/lesson/:lessonId" element={<LessonViewPage />} />

            <Route path="/course/:courseId/lesson/:lessonId/exercise/create" element={<ExerciseCreatePage />} />
            <Route path="/course/:courseId/lesson/:lessonId/exercise/edit/:exerciseId" element={<ExerciseEditPage />} />
            <Route path="/course/:courseId/lesson/:lessonId/exercise/:exerciseId" element={<ExerciseViewPage />} />
            <Route path="/course/:courseId/lesson/:lessonId/exercise/assign/:exerciseId" element={<AssignExercisePage />} />

            <Route path="/assignment/:assignmentId" element={<StudentAssignmentPage />} />
            <Route path="/fixtext" element={<TextFixerPage />} />
          </Routes>
        </Layout>
      </Router>
      <Loader loading={loading} />
    </>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <ScoreProvider>
          <SnackbarProvider 
            maxSnack={3} 
            iconVariant={{
              success: '✅',
              error: '✖️',
              warning: '⚠️',
              info: 'ℹ️',
            }}
          >
            <GlobalStyle />
            <LoadingProvider>              
              <AppWithLoading />
            </LoadingProvider>
          </SnackbarProvider>
        </ScoreProvider>
      </AuthProvider>   
    </ThemeProvider>    
  );
};

export default App;
