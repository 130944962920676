// src/components/Curriculum/CurriculumDetails.tsx
import React from 'react';
import { Curriculum } from '../../types/interfaces';
import { Card, CardContent, Typography } from '@mui/material';

interface CurriculumDetailsProps {
  curriculum: Curriculum;
}

const CurriculumDetails: React.FC<CurriculumDetailsProps> = ({ curriculum }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {curriculum.name}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Kuvaus
        </Typography>
        <Typography variant="body1" gutterBottom>
          {curriculum.description}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Aloituspäivä
        </Typography>
        <Typography variant="body1" gutterBottom>
          {curriculum.startDate}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Lopetuspäivä
        </Typography>
        <Typography variant="body1" gutterBottom>
          {curriculum.endDate}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CurriculumDetails;
