import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LessonForm from '../../components/Lesson/LessonForm';
import { getLesson, updateLesson } from '../../services/lessonService';
import { Container, Typography, CircularProgress, Box } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb';

const LessonEditPage: React.FC = () => {
  const { courseId, lessonId } = useParams<{ courseId: string; lessonId: string }>();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState<{ title: string; description: string; order: number; isActive: boolean } | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (courseId && lessonId) {
        const response = await getLesson(parseInt(courseId, 10), parseInt(lessonId, 10));
        setInitialData(response.data);
      }
      setLoading(false);
    };

    fetchData();
  }, [courseId, lessonId]);

  const handleUpdate = async (data: { title: string; description: string; order: number; isActive: boolean }) => {
    if (courseId && lessonId) {
      await updateLesson(parseInt(courseId, 10), parseInt(lessonId, 10), data);
      navigate(`/course/${courseId}`);
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      {/* Breadcrumb Navigation */}
      {courseId && <Breadcrumb courseId={courseId} lessonId={lessonId} additionalCrumb="Muokkaa oppituntia" />}

      <Box mt={2}>
        <Typography variant="h4" gutterBottom>
          Muokkaa oppituntia
        </Typography>
        {initialData ? (
          <LessonForm initialData={initialData} onSubmit={handleUpdate} />
        ) : (
          <Typography variant="h6">Oppituntia ei löytynyt</Typography>
        )}
      </Box>
    </Container>
  );
};

export default LessonEditPage;
