import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { verifyFillInBlanks } from '../../../common/verifyFillInBlanks'; // Adjust the path to your verify script
import SpeechButton from '../../common/SpeechButton'; // Import the SpeechButton component

interface QuestionPreviewProps {
  question: {
    id: string;
    text: string;
    hint: string;
    answers: string[][];
    voiceCode?: string; // Add voiceCode to support individual voice settings
  };
  isContinuous: boolean;
  onAnswerChange: (questionId: string, answer: string) => void;
  isCorrect?: boolean; // Optional prop to show correct/incorrect icons
}

const QuestionPreview: React.FC<QuestionPreviewProps> = ({
  question,
  isContinuous,
  onAnswerChange,
  isCorrect
}) => {
  const [answer, setAnswer] = useState('');

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(e.target.value);
    onAnswerChange(question.id, e.target.value);
  };

  const answerBox = (
    <TextField
      variant="outlined"
      size="small"
      sx={{ width: '100px', marginX: 1 }}
      value={answer}
      onChange={handleAnswerChange}
    />
  );

  const formattedText = question.text.split('__').reduce((acc, part, index, array) => {
    acc.push(<span key={`text-${index}`}>{part}</span>);
    if (index < array.length - 1) {
      acc.push(<span key={`input-${index}`}>{answerBox}</span>);
    }
    return acc;
  }, [] as React.ReactNode[]);

  return (
    <Box mb={isContinuous ? 0 : 2} display="flex" alignItems="center">
      <Typography variant="body1" component="span">
        {formattedText}
      </Typography>
      {isCorrect !== undefined && (
        <Box ml={1}>
          {isCorrect ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
        </Box>
      )}
      <SpeechButton 
        id={question.id} 
        text={question.text.replace(/__/g, answer)} 
        voiceCode={question.voiceCode || 'en-US-JennyNeural'} // Use question's voiceCode or default to Jenny
      />
      {!isContinuous && (
        <Typography variant="caption" color="textSecondary" display="block">
          <em>Vihje: {question.hint}</em>
        </Typography>
      )}
    </Box>
  );
};

interface FillInBlanksPreviewProps {
  config: {
    questions: {
      id: string;
      text: string;
      answers: string[][];
      hint: string;
      aiCheckEnabled?: boolean;
      voiceCode?: string; // Add voiceCode property here
    }[];
    isContinuous: boolean;
  };
}

const FillInBlanksPreview: React.FC<FillInBlanksPreviewProps> = ({ config }) => {
  const [userAnswers, setUserAnswers] = useState<{ [key: string]: string }>({});
  const [results, setResults] = useState<{ questionId: string; isCorrect: boolean }[]>([]);

  const handleAnswerChange = (questionId: string, answer: string) => {
    setUserAnswers(prev => ({ ...prev, [questionId]: answer }));
  };

  const handleCheckAnswers = () => {
    const answersArray = Object.entries(userAnswers).map(([questionId, answer]) => ({
      questionId,
      answer,
    }));

    const verificationResults = verifyFillInBlanks(answersArray, {
      exerciseType: 'fill_in_the_blanks',
      language: 'fi',
      isContinuous: config.isContinuous,
      questions: config.questions.map(q => ({
        id: q.id,
        text: q.text,
        answers: q.answers,
        hint: q.hint,
        aiCheckEnabled: q.aiCheckEnabled ?? false,
        voiceCode: q.voiceCode || "en-US-JennyNeural" // Provide a default value
      })),      
    });

    setResults(verificationResults);
  };

  return (
    <Box mt={2}>
      {config.isContinuous ? (
        <QuestionPreview
          question={{
            id: 'continuous',
            text: config.questions.map(q => q.text).join(' '),
            answers: config.questions.flatMap(q => q.answers),
            hint: config.questions.map(q => q.hint).join(' | '),
            voiceCode: config.questions[0]?.voiceCode // Use the first question's voiceCode as default for continuous mode
          }}
          isContinuous={true}
          onAnswerChange={handleAnswerChange}
        />
      ) : (
        config.questions.map((question, index) => {
          const result = results.find(res => res.questionId === question.id);
          return (
            <QuestionPreview
              key={index}
              question={question}
              isContinuous={false}
              onAnswerChange={handleAnswerChange}
              isCorrect={result?.isCorrect}
            />
          );
        })
      )}

      <Box mt={2}>
        <Button variant="contained" onClick={handleCheckAnswers}>
          Tarkista vastaukset
        </Button>
      </Box>
    </Box>
  );
};

export default FillInBlanksPreview;
