import React, { useEffect, useState } from 'react';
import { getLessons, deleteLesson } from '../../services/lessonService';
import LessonItem from './LessonItem';
import { Lesson } from '../../types/interfaces';
import { Container, Grid } from '@mui/material';

interface LessonListProps {
  courseId: string;
}

const LessonList: React.FC<LessonListProps> = ({ courseId }) => {
  const [lessons, setLessons] = useState<Lesson[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (courseId) {
        const response = await getLessons(parseInt(courseId, 10));
        setLessons(response.data);
      }
    };

    fetchData();
  }, [courseId]);

  const handleDelete = async (lessonId: number) => {
    await deleteLesson(parseInt(courseId, 10), lessonId);
    setLessons((prev) => prev.filter((lesson) => lesson.id !== lessonId));
  };

  return (
    <Container>
      <Grid container spacing={3}>
        {lessons.map((lesson) => (
          <Grid item xs={12} key={lesson.id}>
            <LessonItem lesson={lesson} onDelete={handleDelete} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default LessonList;
