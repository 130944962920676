import React from 'react';
import { Course } from '../../types/interfaces';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import SchoolIcon from '@mui/icons-material/School';
import DescriptionIcon from '@mui/icons-material/Description';

interface CourseDetailsProps {
  course: Course;
}

const CourseDetails: React.FC<CourseDetailsProps> = ({ course }) => {
  return (
    <Card sx={{ p: 2, mb: 3, backgroundColor: '#f9f9f9' }}>
      <CardContent>
        {/* Course Name */}
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
          {course.name}
        </Typography>

        {/* Divider */}
        <Divider sx={{ my: 2 }} />

        {/* Course Description */}
        <Box display="flex" alignItems="center" mb={2}>
          <DescriptionIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Kuvaus</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {course.description}
        </Typography>

        {/* Course Dates */}
        <Box display="flex" alignItems="center" mb={2}>
          <EventIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Ajankohta</Typography>
        </Box>
        <Typography variant="body1">
          <strong>Aloituspäivä:</strong> {course.startDate}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Lopetuspäivä:</strong> {course.endDate}
        </Typography>

        {/* Subject and Specification */}
        <Box display="flex" alignItems="center" mb={2}>
          <SchoolIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Aine</Typography>
        </Box>
        <Typography variant="body1">
          <strong>Aine:</strong> {course.subject}
        </Typography>
        <Typography variant="body1">
          <strong>Aineen kuvaus:</strong> {course.subjectSpecification}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CourseDetails;
