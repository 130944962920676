import React, { useState, useEffect, useMemo } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Box, Typography, TextField, IconButton, Collapse, Button } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import SpeechButton from '../../common/SpeechButton';
import { simplifyMaterial } from '../../../services/aiExerciseService'; // Import the simplification service
interface QuestionStudentProps {
  questionId: string;
  questionText: string;
  answer: string;
  onAnswerChange: (questionId: string, answer: string) => void;
  feedback: { grade: number; feedback: string; score: number } | null;
  disabled: boolean;
  voiceCode?: string;
}

const QuestionStudent: React.FC<QuestionStudentProps> = ({
  questionId,
  questionText,
  answer,
  onAnswerChange,
  feedback,
  disabled,
  voiceCode,
}) => {
  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAnswerChange(questionId, e.target.value);
  };

  const pastelColors = ['#f8d7da', '#ffe5b4', '#fff3cd', '#d4edda', '#c3e6cb', '#b9f6ca'];
  const feedbackStyle = feedback ? { backgroundColor: pastelColors[feedback.grade] } : {};

  return (
    <Box mb={2} p={2} border={1} borderRadius={1}>
      <Box display="flex" alignItems="center">
        <Typography variant="body1" flexGrow={1}>
          {questionText}
        </Typography>
        <SpeechButton id={questionId} text={questionText} voiceCode={voiceCode} />
      </Box>
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        margin="dense"
        value={answer}
        onChange={handleAnswerChange}
        disabled={disabled}
      />
      {feedback && (
        <Box mt={1} p={1} borderRadius={1} style={feedbackStyle}>
          <Typography variant="body2" color="textSecondary">
            Palaute: {feedback.feedback}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Pisteet: {feedback.score}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

interface MaterialBasedStudentProps {
  config: {
    capturedText: string;
    questions: string[];
    voiceCode: string;
  };
  initialAnswers: { [key: string]: string };
  onAnswerChange: (questionId: string, answer: string) => void;
  disabled: boolean;
  assignmentStatus: 'New' | 'Active' | 'InReview';
  hasSubmitted: boolean;
  feedback: { grade: number; feedback: string; score: number }[] | null;
}

const MaterialBasedStudent: React.FC<MaterialBasedStudentProps> = ({
  config,
  initialAnswers,
  onAnswerChange,
  disabled,
  assignmentStatus,
  hasSubmitted,
  feedback,
}) => {
  const { capturedText, questions, voiceCode } = config;

  // Initialize editor state from capturedText
  const [editorState, setEditorState] = useState<EditorState>(
    capturedText
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(capturedText)))
      : EditorState.createEmpty(),
  );

  const [simplifiedText, setSimplifiedText] = useState<string | null>(null);
  const [showSimplified, setShowSimplified] = useState(false); // Toggle state
  const [answers, setAnswers] = useState<{ [key: string]: string }>(initialAnswers);
  const [isChatOpen, setIsChatOpen] = useState(false);

  // Fetch simplified text when the component loads
  useEffect(() => {
    const fetchSimplifiedText = async () => {
      try {
        const plainText = editorState.getCurrentContent().getPlainText();
        const response = await simplifyMaterial(plainText); // Call the API
        setSimplifiedText(response.data);
      } catch (error) {
        console.error('Error fetching simplified text:', error);
      }
    };

    fetchSimplifiedText();
  }, [editorState]);

  const plainText = editorState.getCurrentContent().getPlainText();

  const toggleSimplifiedView = () => {
    setShowSimplified(!showSimplified);
  };

  const handleAnswerChange = (questionId: string, answer: string) => {
    const updatedAnswers = { ...answers, [questionId]: answer };
    setAnswers(updatedAnswers);
    onAnswerChange(questionId, answer);
  };

  const toggleChat = () => setIsChatOpen(!isChatOpen);

  // Save editor content to the backend
  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);
    const rawContent = JSON.stringify(convertToRaw(state.getCurrentContent()));
    config.capturedText = rawContent; // Update config with serialized content
  };

  const transformedQuestions = useMemo(() => {
    return questions.map((text: string, index: number) => ({
      id: index.toString(),
      text,
    }));
  }, [questions]);

  return (
    <Box>
      {/* Display Material with Rich Text Editor */}
      <Box mb={3} p={2} border={1} borderColor="lightblue" borderRadius={1} bgcolor="lightblue">
        <Typography variant="h6">
          {showSimplified ? 'Yksinkertaistettu Materiaali' : 'Materiaali'}
        </Typography>
        {showSimplified && simplifiedText ? (
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {simplifiedText}
          </Typography>
        ) : (
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            toolbar={{ options: [] }}
          />
        )}
        <Button variant="contained" onClick={toggleSimplifiedView} sx={{ mt: 2 }}>
          {showSimplified ? 'Näytä alkuperäinen teksti' : 'Näytä yksinkertaistettu teksti'}
        </Button>
        <SpeechButton 
          id="material-speech" 
          text={showSimplified && simplifiedText ? simplifiedText : plainText} 
          voiceCode={voiceCode} 
        />
      </Box>


      {/* Display Questions and Feedback */}
      <Box>
        <Typography variant="h6">Kysymykset ja vastaukset</Typography>
        {transformedQuestions.map(({ id, text }, index) => (
          <QuestionStudent
            key={id}
            questionId={id}
            questionText={text}
            answer={answers[id] || ''}
            onAnswerChange={handleAnswerChange}
            feedback={feedback ? feedback[index] : null}
            disabled={disabled || hasSubmitted}
            voiceCode={voiceCode}
          />
        ))}
      </Box>

      {/* AI Chat Section */}
      <Box mt={3} border={1} borderRadius={1} p={2}>
        <Box display="flex" alignItems="center" onClick={toggleChat} style={{ cursor: 'pointer' }}>
          <Typography variant="h6">Keskustele AI:n kanssa</Typography>
          <IconButton size="small">{isChatOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </Box>
        <Collapse in={isChatOpen}>
          <Typography variant="body2" color="textSecondary">
            Tämä on AI:n kanssa käytävä keskustelu liittyen materiaaliin. Voit esittää kysymyksiä materiaalin
            perusteella.
          </Typography>
        </Collapse>
      </Box>
    </Box>
  );
};

export default MaterialBasedStudent;
