import React from 'react';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ScoreIcon from '@mui/icons-material/Score';
import EventIcon from '@mui/icons-material/Event';
import { Exercise } from '../../types/interfaces';

interface ExerciseDetailsProps {
  exercise: Exercise;
}

const ExerciseDetails: React.FC<ExerciseDetailsProps> = ({ exercise }) => {
  return (
    <Card sx={{ p: 2, mb: 3, backgroundColor: '#f9f9f9' }}>
      <CardContent>
        {/* Exercise Type */}
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
          Harjoitus {exercise.order}
        </Typography>

        {/* Divider */}
        <Divider sx={{ my: 2 }} />

        {/* Exercise Instructions */}
        <Box display="flex" alignItems="center" mb={2}>
          <DescriptionIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Ohjeet</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {exercise.instructions}
        </Typography>

        {/* Exercise Additional Info */}
        <Box display="flex" alignItems="center" mb={2}>
          <DescriptionIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Lisätiedot</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {exercise.additionalInfo || 'Ei lisätietoja'}
        </Typography>

        {/* Exercise Order */}
        <Box display="flex" alignItems="center" mb={2}>
          <FormatListNumberedIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Järjestys</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {exercise.order}
        </Typography>

        {/* Exercise Max Score */}
        <Box display="flex" alignItems="center" mb={2}>
          <ScoreIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Maksimipistemäärä</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {exercise.maxScore}
        </Typography>

        {/* Exercise Creation Date */}
        <Box display="flex" alignItems="center" mb={2}>
          <EventIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Luotu</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {new Date(exercise.createdAt).toLocaleDateString()}
        </Typography>

        {/* Exercise Update Date */}
        <Box display="flex" alignItems="center" mb={2}>
          <EventIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Päivitetty</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {new Date(exercise.updatedAt).toLocaleDateString()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ExerciseDetails;
