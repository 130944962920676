import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LessonForm from '../../components/Lesson/LessonForm';
import { createLesson } from '../../services/lessonService';
import Breadcrumb from '../../components/Breadcrumb';

const LessonCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const { courseId } = useParams<{ courseId: string }>();

  const handleCreate = async (data: { title: string; description: string; order: number; isActive: boolean }) => {
    if (courseId) {
      await createLesson(parseInt(courseId, 10), data);
      navigate(`/course/${courseId}`);
    }
  };

  return (
    <div>
      {/* Breadcrumb Navigation */}
      {courseId && <Breadcrumb courseId={courseId} additionalCrumb="New Lesson *" />}

      <h1>Luo oppitunti</h1>
      <LessonForm onSubmit={handleCreate} />
    </div>
  );
};

export default LessonCreatePage;
