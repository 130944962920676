// src/components/Course/CourseItem.tsx
import React, { useState } from 'react';
import { Course } from '../../types/interfaces'; // Import the Course type
import { Card, CardContent, Typography, CardActions, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const CourseItem: React.FC<{ course: Course, onDelete: (id: number) => void }> = ({ course, onDelete }) => {
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    await onDelete(course.id);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {course.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {course.description}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {course.startDate} - {course.endDate}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" component={RouterLink} to={`/course/${course.id}`}>
          Näytä
        </Button>
        <Button size="small" component={RouterLink} to={`/course/edit/${course.id}`}>
          Muokkaa
        </Button>
        <Button size="small" color="secondary" onClick={handleClickOpen}>
          Poista
        </Button>
      </CardActions>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Vahvista poisto"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oletko varma että haluat poistaa kurssin "{course.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Peruuta
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Poista
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default CourseItem;
