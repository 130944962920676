// src/pages/Curriculums/CurriculumEditPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CurriculumForm from '../../components/Curriculum/CurriculumForm';
import { getCurriculum, updateCurriculum } from '../../services/curriculumService';
import { Container, Typography, CircularProgress } from '@mui/material';

const CurriculumEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState<{ name: string; description: string; startDate: string; endDate: string; } | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await getCurriculum(parseInt(id, 10));
        setInitialData(response.data);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  const handleUpdate = async (data: { name: string; description: string; startDate: string; endDate: string; }) => {
    if (id) {
      await updateCurriculum(parseInt(id, 10), data);
      navigate('/curriculum');
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Muokkaa opinto-ohjelmaa
      </Typography>
      {initialData ? (
        <CurriculumForm initialData={initialData} onSubmit={handleUpdate} />
      ) : (
        <Typography variant="h6">Opinto-ohjelmaa ei löytynyt</Typography>
      )}
    </Container>
  );
};

export default CurriculumEditPage;
