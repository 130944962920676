import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FinnishNumberInput from '../../inputs/FinnishNumberInput'; // Import the FinnishNumberInput component
import { Question, verifyMathArit2Operand1Phase } from '../../../common/verifyMathArit2Operand1Phase';

interface MathArit2Operand1PhaseStudentProps {
  config: {
    questions: Question[];
  };
  onAnswerChange: (index: number, answer: string) => void;
  disabled: boolean;
  feedback: { isCorrect: boolean; questionIndex: number }[] | null;
  initialAnswers: string[];
  assignmentStatus: 'New' | 'Active' | 'InReview';
  hasSubmitted: boolean;
}

const MathArit2Operand1PhaseStudent: React.FC<MathArit2Operand1PhaseStudentProps> = ({
  config,
  onAnswerChange,
  disabled,
  feedback,
  initialAnswers,
  assignmentStatus,
  hasSubmitted,
}) => {
  const [randomizedQuestions, setRandomizedQuestions] = useState<Question[]>([]);
  const [userAnswers, setUserAnswers] = useState<string[]>(initialAnswers);
  const [currentFeedback, setCurrentFeedback] = useState<{ isCorrect: boolean; questionIndex: number }[] | null>(null);

  useEffect(() => {
    setUserAnswers(initialAnswers); // Reset userAnswers whenever initialAnswers change
  }, [initialAnswers]);

  useEffect(() => {
    const generateRandomNumber = (digitCount: number) => {
      const min = Math.pow(10, digitCount - 1);
      const max = Math.pow(10, digitCount) - 1;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const randomized = config.questions.map((question) => {
      const operand1 = question.randomizeAll || question.randomizeOperands
        ? generateRandomNumber(question.digitCount)
        : question.operand1;

      const operand2 = question.randomizeAll
        ? generateRandomNumber(question.digitCount)
        : question.randomizeOperands
        ? generateRandomNumber(question.digitCount)
        : question.operand2;

      return {
        ...question,
        operand1,
        operand2,
      };
    });
    setRandomizedQuestions(randomized);
  }, [config.questions]);

  useEffect(() => {
    // Recalculate feedback when assignment is in review or after submission
    if ((assignmentStatus === 'InReview' || hasSubmitted) && randomizedQuestions.length) {
      const evaluatedFeedback = randomizedQuestions.map((question, index) => {
        const result = verifyMathArit2Operand1Phase(question, userAnswers[index]);
        return {
          questionIndex: index,
          isCorrect: result.isCorrect,
          correctAnswer: result.correctAnswer,
        };
      });
      setCurrentFeedback(evaluatedFeedback);
    }
  }, [randomizedQuestions, userAnswers, assignmentStatus, hasSubmitted]);

  const handleInputChange = (value: string, index: number) => {
    const newAnswers = [...userAnswers];
    newAnswers[index] = value;
    setUserAnswers(newAnswers);
    onAnswerChange(index, value);
  };

  const shouldShowFeedback = assignmentStatus === 'InReview' || hasSubmitted;

  return (
    <Box mt={4}>
      <Typography variant="h5" mb={2}>Tehtävä</Typography>
      {randomizedQuestions.map((question, index) => {
        const feedbackItem = currentFeedback ? currentFeedback.find(fb => fb.questionIndex === index) : null;

        return (
          <Box key={index} mb={4} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6" component="div">
              {question.operand1} {question.operator} {question.operand2} =
            </Typography>

            <FinnishNumberInput
              value={userAnswers[index] || ''} // Ensure value is empty if reset
              onChange={(value) => handleInputChange(value, index)}
              disabled={disabled}
            />

            {shouldShowFeedback && feedbackItem && (
              feedbackItem.isCorrect ? (
                <CheckCircleIcon sx={{ color: 'green' }} />
              ) : (
                <CancelIcon sx={{ color: 'red' }} />
              )
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default MathArit2Operand1PhaseStudent;
