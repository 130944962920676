import api from './api';

export const extractText = async (blob: Blob) => {  
  try {   
    const formData = new FormData();
    formData.append('image', blob, 'capture.png');

    const response = await api.post('/material/extractfromimage', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  } catch (error) {
    console.error('Error extracting text:', error);
    throw error;
  }
};