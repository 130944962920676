import React, { useEffect, useState } from 'react';
import { getExercises, deleteExercise } from '../../services/exerciseService'; // Import the necessary services
import ExerciseItem from './ExerciseItem'; // Import the ExerciseItem component
import { Exercise } from '../../types/interfaces'; // Import the Exercise type
import { Container, Grid } from '@mui/material';

interface ExerciseListProps {
  lessonId: string; 
  courseId: string;
}

const ExerciseList: React.FC<ExerciseListProps> = ({ lessonId, courseId }) => {
  const [exercises, setExercises] = useState<Exercise[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (lessonId) {
        const response = await getExercises(parseInt(lessonId, 10));
        setExercises(response.data);
      }
    };

    fetchData();
  }, [lessonId]);

  const handleDelete = async (exerciseId: number) => {
    await deleteExercise(parseInt(lessonId, 10), exerciseId);
    setExercises((prev) => prev.filter((exercise) => exercise.id !== exerciseId));
  };

  return (
    <Container>
      <Grid container spacing={3}>
        {exercises.map((exercise) => (
          <Grid item xs={12} key={exercise.id}>
            <ExerciseItem exercise={exercise} onDelete={handleDelete} courseId={courseId} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ExerciseList;
