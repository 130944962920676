import React, { useMemo } from 'react';
import { Card, CardContent, Typography, Divider, Box } from '@mui/material';
import MathArit2Operand1PhaseStudent from './AssignmentTypes/MathArit2Operand1PhaseStudent';
import MathAritRoundingStudent from './AssignmentTypes/MathAritRoundingStudent';
import FillInTheBlanksStudent from './AssignmentTypes/FillInTheBlanksStudent';
import PairingStudent from './AssignmentTypes/PairingStudent';
import MaterialBasedStudent from './AssignmentTypes/MaterialBasedStudent';
import SortToBoxesStudent from './AssignmentTypes/SortToBoxesStudent';

interface StudentAssignmentProps {
  type: string;
  config: any;
  instructions: string;
  order: number;
  additionalInfo?: string;
  onAnswerChange: (index: number, answer: any) => void;
  onSubmitAnswers: () => void;
  disabled: boolean;
  feedback: any;
  initialAnswers: any;
  assignmentStatus: 'New' | 'Active' | 'InReview';
  hasSubmitted: boolean;
}

const StudentAssignment: React.FC<StudentAssignmentProps> = ({
  type,
  config,
  instructions,
  order,
  additionalInfo,
  onAnswerChange,
  disabled,
  feedback,
  initialAnswers,
  assignmentStatus,
  hasSubmitted,
}) => {
  // Moved useMemo hooks to the top level
  const initialPairingAnswers = useMemo(() => {
    if (type === 'pairing') {
      return config.questions.reduce(
        (
          acc: { [key: string]: { id: string; text: string } },
          question: { id: string; termBOptions: string[] },
          index: number
        ) => {
          const answerText = initialAnswers[index] || '';
          const optionIndex = question.termBOptions.findIndex((opt) => opt === answerText);
          if (optionIndex !== -1) {
            const optionId = `${question.id}-option-${optionIndex}`;
            acc[question.id] = { id: optionId, text: answerText };
          } else if (answerText) {
            acc[question.id] = { id: '', text: answerText };
          }
          return acc;
        },
        {}
      );
    }
    return {};
  }, [type, config.questions, initialAnswers]);

  const initialAssignedTerms = useMemo(() => {
    if (type === 'sort_to_boxes') {
      const terms = config.terms || [];
      if (Array.isArray(initialAnswers) && initialAnswers.length === terms.length) {
        return terms.map((term: any, index: number) => {
          const assignedContainerId = initialAnswers[index];
          return {
            ...term,
            containerId: assignedContainerId || '',
          };
        });
      } else {
        // If no initial answers or mismatch in length, use the terms from config
        return terms;
      }
    }
    return [];
  }, [type, config.terms, initialAnswers]);

  const renderExercise = () => {
    switch (type) {
      case 'math_arit_2operand_1phase':
        return (
          <MathArit2Operand1PhaseStudent
            config={config}
            onAnswerChange={onAnswerChange}
            disabled={disabled}
            feedback={feedback}
            initialAnswers={initialAnswers}
            assignmentStatus={assignmentStatus}
            hasSubmitted={hasSubmitted}
          />
        );
      case 'math_arit_rounding_precision':
        return (
          <MathAritRoundingStudent
            config={config}
            onAnswerChange={onAnswerChange}
            disabled={disabled}
            feedback={feedback}
            initialAnswers={initialAnswers}
            assignmentStatus={assignmentStatus}
            hasSubmitted={hasSubmitted}
          />
        );
      case 'math_arit_2operand_2phase':
        return (
          <MathArit2Operand1PhaseStudent
            config={config}
            onAnswerChange={onAnswerChange}
            disabled={disabled}
            feedback={feedback}
            initialAnswers={initialAnswers}
            assignmentStatus={assignmentStatus}
            hasSubmitted={hasSubmitted}
          />
        );
      case 'fill_in_the_blanks': {
        const initialAnswersObj = config.questions.reduce(
          (acc: { [key: string]: string }, question: { id: string }, index: number) => {
            acc[question.id] = initialAnswers[index] || '';
            return acc;
          },
          {}
        );

        const handleAnswerChangeForBlanks = (questionId: string, answer: string) => {
          const questionIndex = config.questions.findIndex((q: { id: string }) => q.id === questionId);
          if (questionIndex !== -1) {
            onAnswerChange(questionIndex, answer);
          }
        };

        return (
          <FillInTheBlanksStudent
            config={config}
            onAnswerChange={handleAnswerChangeForBlanks}
            disabled={disabled}
            initialAnswers={initialAnswersObj}
            assignmentStatus={assignmentStatus}
            hasSubmitted={hasSubmitted}
          />
        );
      }
      case 'pairing': {
        const handleAnswerChangeForPairing = (
          questionId: string,
          answer: { id: string; text: string }
        ) => {
          const questionIndex = config.questions.findIndex((q: { id: string }) => q.id === questionId);
          if (questionIndex !== -1) {
            onAnswerChange(questionIndex, answer.text);
          }
        };

        return (
          <PairingStudent
            config={config}
            onAnswerChange={handleAnswerChangeForPairing}
            disabled={disabled}
            initialAnswers={initialPairingAnswers}
            assignmentStatus={assignmentStatus}
            hasSubmitted={hasSubmitted}
          />
        );
      }
      case 'material_based_ai_assisted': {
        const initialMaterialAnswers: { [key: string]: string } = {};
        config.questions.forEach((question: string, index: number) => {
          initialMaterialAnswers[index.toString()] = initialAnswers[index] || '';
        });

        const handleAnswerChangeForMaterialBased = (questionId: string, answer: string) => {
          const questionIndex = parseInt(questionId, 10);
          if (!isNaN(questionIndex)) {
            onAnswerChange(questionIndex, answer);
          }
        };

        const transformedFeedback =
          feedback?.map((item: any) => ({
            grade: item.grade,
            feedback: item.feedback,
            score: item.score,
          })) || [];

        return (
          <MaterialBasedStudent
            config={config}
            onAnswerChange={handleAnswerChangeForMaterialBased}
            disabled={disabled}
            initialAnswers={initialMaterialAnswers}
            assignmentStatus={assignmentStatus}
            hasSubmitted={hasSubmitted}
            feedback={transformedFeedback}
          />
        );
      }
      case 'sort_to_boxes': {
        const handleAnswerChangeForSortToBoxes = (updatedTerms: any[]) => {
          const answers = updatedTerms.map((term) => term.containerId || '');
          answers.forEach((answer, index) => {
            onAnswerChange(index, answer);
          });
        };

        return (
          <SortToBoxesStudent
            config={config}
            onAnswerChange={handleAnswerChangeForSortToBoxes}
            disabled={disabled}
            feedback={feedback}
            initialAssignedTerms={initialAssignedTerms}
            assignmentStatus={assignmentStatus}
            hasSubmitted={hasSubmitted}
          />
        );
      }
      default:
        return (
          <Typography variant="body1" color="error">
            Unknown exercise type: {type}
          </Typography>
        );
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Tehtävä {order}: {instructions}
        </Typography>
        {additionalInfo && (
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Lisätietoja: {additionalInfo}
          </Typography>
        )}

        <Divider sx={{ marginY: 2 }} />

        <Box mt={2}>{renderExercise()}</Box>
      </CardContent>
    </Card>
  );
};

export default StudentAssignment;
