import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { verifyPairingAnswers } from '../../../common/verifyPairing'; // Adjust path as necessary

interface PairingExerciseConfig {
  exerciseType: string;
  isVisual: boolean;
  questions: {
    id: string;
    termA: { text: string; language: string };
    termBOptions: string[];
    termBLanguage: string;
    hint: string;
  }[];
}

interface PairingStudentProps {
  config: PairingExerciseConfig;
  initialAnswers: { [key: string]: { id: string; text: string } };
  disabled: boolean;
  onAnswerChange: (questionId: string, answer: { id: string; text: string }) => void;
  assignmentStatus: 'New' | 'Active' | 'InReview';
  hasSubmitted: boolean;
}

const DraggableTermB: React.FC<{ text: string; id: string; disabled: boolean }> = ({
  text,
  id,
  disabled,
}) => {
  const [, drag] = useDrag(() => ({
    type: 'termB',
    item: { id, text },
    canDrag: !disabled,
  }));

  return (
    <Box
      ref={drag}
      p={1}
      border={1}
      borderColor="grey.300"
      borderRadius={2}
      m={1}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      sx={{
        textAlign: 'center',
        width: '120px',
        height: '40px',
        lineHeight: '40px',
      }}
    >
      {text}
    </Box>
  );
};

const DroppableTermA: React.FC<{
  questionId: string;
  current: string | null;
  onDrop: (questionId: string, answer: { id: string; text: string }) => void;
  disabled: boolean;
}> = ({ questionId, current, onDrop, disabled }) => {
  const [, drop] = useDrop(() => ({
    accept: 'termB',
    drop: (item: { id: string; text: string }) => {
      if (!disabled) onDrop(questionId, item);
    },
  }));

  return (
    <Box
      ref={drop}
      p={1}
      border={2}
      borderColor="grey.500"
      borderRadius={2}
      m={1}
      minWidth="120px"
      minHeight="40px"
      bgcolor={disabled ? 'grey.100' : 'white'}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {current || 'Raahaa tähän'}
    </Box>
  );
};

const PairingStudent: React.FC<PairingStudentProps> = ({
  config,
  initialAnswers,
  disabled,
  onAnswerChange,
  assignmentStatus,
  hasSubmitted,
}) => {
  const [userAnswers, setUserAnswers] = useState<{ [key: string]: { id: string; text: string } }>(
    initialAnswers
  );
  const [feedback, setFeedback] = useState<{ questionId: string; isCorrect: boolean }[]>([]);
  const [availableOptions, setAvailableOptions] = useState<{ id: string; text: string }[]>([]);

  // **Reset userAnswers when initialAnswers change**
  useEffect(() => {
    setUserAnswers(initialAnswers);
  }, [initialAnswers]);

  useEffect(() => {
    const allOptions = config.questions.flatMap((q) =>
      q.termBOptions.map((option, index) => ({
        id: `${q.id}-option-${index}`,
        text: option,
      }))
    );

    const selectedOptionIds = Object.values(userAnswers).map((answer) => answer.id);
    const filteredOptions = allOptions.filter((option) => !selectedOptionIds.includes(option.id));

    setAvailableOptions(filteredOptions.sort(() => Math.random() - 0.5));
  }, [config.questions, userAnswers, initialAnswers]); // Added initialAnswers to dependencies

  useEffect(() => {
    const shouldCalculateFeedback =
      assignmentStatus === 'InReview' || hasSubmitted || feedback.length > 0;

    if (shouldCalculateFeedback && config.questions.length) {
      const answersArray = Object.entries(userAnswers).map(([questionId, answer]) => ({
        questionId,
        selectedTermB: {
          text: answer.text,
          language: config.questions.find((q) => q.id === questionId)?.termBLanguage || '',
        },
      }));
      const verificationResults = verifyPairingAnswers(answersArray, config);
      setFeedback(verificationResults);
    } else if (!shouldCalculateFeedback) {
      setFeedback([]); // Clear feedback when not in review or submitted
    }
  }, [userAnswers, assignmentStatus, hasSubmitted, config, feedback.length]);

  const handleAnswerChange = (questionId: string, answer: { id: string; text: string }) => {
    setUserAnswers((prev) => ({ ...prev, [questionId]: answer }));
    onAnswerChange(questionId, answer);

    setAvailableOptions((prev) => prev.filter((option) => option.id !== answer.id));

    // Recalculate feedback dynamically
    const answersArray = Object.entries({ ...userAnswers, [questionId]: answer }).map(
      ([qId, ans]) => ({
        questionId: qId,
        selectedTermB: {
          text: ans.text,
          language: config.questions.find((q) => q.id === qId)?.termBLanguage || '',
        },
      })
    );
    const updatedFeedback = verifyPairingAnswers(answersArray, config);
    setFeedback(updatedFeedback);
  };

  const handleRemoveAnswer = (questionId: string) => {
    const removedAnswer = userAnswers[questionId];
    setUserAnswers((prev) => {
      const newAnswers = { ...prev };
      delete newAnswers[questionId];
      return newAnswers;
    });

    onAnswerChange(questionId, { id: '', text: '' });

    if (removedAnswer && !availableOptions.some((option) => option.id === removedAnswer.id)) {
      setAvailableOptions((prev) => [...prev, removedAnswer]);
    }

    // Recalculate feedback after removing
    const answersArray = Object.entries(userAnswers)
      .filter(([qId]) => qId !== questionId)
      .map(([qId, ans]) => ({
        questionId: qId,
        selectedTermB: {
          text: ans.text,
          language: config.questions.find((q) => q.id === qId)?.termBLanguage || '',
        },
      }));
    const updatedFeedback = verifyPairingAnswers(answersArray, config);
    setFeedback(updatedFeedback);
  };

  const handleReset = () => {
    const allOptions = config.questions.flatMap((q) =>
      q.termBOptions.map((option, index) => ({
        id: `${q.id}-option-${index}`,
        text: option,
      }))
    );

    setAvailableOptions(allOptions.sort(() => Math.random() - 0.5));
    setUserAnswers({}); // Clear local state

    // Notify parent about the reset
    config.questions.forEach((question) => {
      onAnswerChange(question.id, { id: '', text: '' });
    });

    // Reset feedback
    setFeedback([]);
  };

  const shouldShowFeedback = assignmentStatus === 'InReview' || hasSubmitted;

  return (
    <DndProvider backend={HTML5Backend}>
      <Box mt={2}>
        {config.isVisual ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Valitse termit
              </Typography>
              <Box display="flex" flexWrap="wrap">
                {availableOptions.map((option) => (
                  <DraggableTermB
                    key={option.id}
                    id={option.id}
                    text={option.text}
                    disabled={disabled}
                  />
                ))}
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleReset}
                disabled={disabled}
              >
                Nollaa valinnat
              </Button>
            </Grid>
            <Grid item xs={12} md={8}>
              {config.questions.map((question) => {
                const feedbackItem = feedback.find((f) => f.questionId === question.id);
                return (
                  <Box key={question.id} mb={2} display="flex" alignItems="center">
                    <Typography
                      variant="body1"
                      sx={{ width: '200px', marginRight: '16px', textAlign: 'right' }}
                    >
                      {question.termA.text} ({question.termA.language}):
                    </Typography>
                    <DroppableTermA
                      questionId={question.id}
                      current={userAnswers[question.id]?.text || null}
                      onDrop={handleAnswerChange}
                      disabled={disabled}
                    />
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => handleRemoveAnswer(question.id)}
                      disabled={disabled || !userAnswers[question.id]}
                      sx={{ ml: 2 }}
                    >
                      Poista
                    </Button>
                    {shouldShowFeedback && feedbackItem && (
                      <Box ml={2}>
                        {feedbackItem.isCorrect ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        ) : (
          <Box>
            {config.questions.map((question) => {
              const feedbackItem = feedback.find((f) => f.questionId === question.id);
              return (
                <Box key={question.id} mb={2} display="flex" alignItems="center">
                  <Typography
                    variant="body1"
                    mr={2}
                    sx={{ width: '250px' }}
                  >
                    {question.termA.text} ({question.termA.language}):
                  </Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={userAnswers[question.id]?.text || ''}
                    onChange={(e) =>
                      handleAnswerChange(question.id, { id: '', text: e.target.value })
                    }
                    disabled={disabled}
                    sx={{ width: '200px' }}
                  />
                  {shouldShowFeedback && feedbackItem && (
                    <Box ml={2}>
                      {feedbackItem.isCorrect ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </DndProvider>
  );
};

export default PairingStudent;
