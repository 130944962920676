import React, { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Typography,
  Divider,
  TextField,
  IconButton,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import SortToBoxesQuestion from './SortToBoxesQuestion';
import SortToBoxesPreview from './SortToBoxesPreview';

interface Container {
  id: string;
  name: string;
}

interface Term {
  id: string;
  text: string;
  containerId: string;
}

interface SortToBoxesConfigType {
  exerciseType: string;
  containers: Container[];
  terms: Term[];
}

interface SortToBoxesConfigProps {
  initialConfig?: any;
  onConfigChange: (config: any) => void;
}

const SortToBoxesConfig: React.FC<SortToBoxesConfigProps> = ({
  initialConfig = {},
  onConfigChange,
}) => {
  const [containers, setContainers] = useState<Container[]>(
    initialConfig.containers || []
  );
  const [terms, setTerms] = useState<Term[]>(initialConfig.terms || []);

  const handleConfigChange = useCallback(() => {
    const config: SortToBoxesConfigType = {
      exerciseType: 'sort_to_boxes',
      containers,
      terms,
    };
    onConfigChange(config);
  }, [containers, terms, onConfigChange]);

  useEffect(() => {
    handleConfigChange();
  }, [handleConfigChange]);

  const addContainer = () => {
    setContainers([
      ...containers,
      {
        id: uuidv4(),
        name: '',
      },
    ]);
  };

  const updateContainer = (index: number, updatedContainer: Container) => {
    const updatedContainers = [...containers];
    updatedContainers[index] = updatedContainer;
    setContainers(updatedContainers);
  };

  const removeContainer = (index: number) => {
    const containerId = containers[index].id;
    setContainers(containers.filter((_, i) => i !== index));

    // Remove terms associated with this container
    setTerms(terms.filter((term) => term.containerId !== containerId));
  };

  const addTerm = () => {
    setTerms([
      ...terms,
      {
        id: uuidv4(),
        text: '',
        containerId: '', // Unassigned initially
      },
    ]);
  };

  const updateTerm = (index: number, updatedTerm: Term) => {
    const updatedTerms = [...terms];
    updatedTerms[index] = updatedTerm;
    setTerms(updatedTerms);
  };

  const removeTerm = (index: number) => {
    setTerms(terms.filter((_, i) => i !== index));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Lajittele laatikoihin - Asetukset
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Containers Section */}
      <Typography variant="h6" gutterBottom>
        Kontit
      </Typography>
      {containers.map((container, index) => (
        <Box key={container.id} display="flex" alignItems="center" mb={2}>
          <TextField
            label={`Kontti ${index + 1}`}
            value={container.name}
            onChange={(e) =>
              updateContainer(index, { ...container, name: e.target.value })
            }
            sx={{ mr: 2 }}
          />
          <IconButton
            aria-label="delete"
            onClick={() => removeContainer(index)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="contained"
        startIcon={<AddCircleOutlineIcon />}
        onClick={addContainer}
        sx={{ mb: 4 }}
      >
        Lisää kontti
      </Button>

      {/* Terms Section */}
      <Typography variant="h6" gutterBottom>
        Termit
      </Typography>
      {terms.map((term, index) => (
        <SortToBoxesQuestion
          key={term.id}
          index={index}
          term={term}
          containers={containers}
          onTermChange={updateTerm}
          onRemoveTerm={removeTerm}
        />
      ))}
      <Button
        variant="contained"
        startIcon={<AddCircleOutlineIcon />}
        onClick={addTerm}
        sx={{ mt: 2 }}
      >
        Lisää termi
      </Button>

      <Box mt={4}>
        <Typography variant="h6">Esikatselu</Typography>
        <SortToBoxesPreview
          config={{
            containers,
            terms,
          }}
        />
      </Box>
    </Box>
  );
};

export default SortToBoxesConfig;
