import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { verifyMathAritRoundingPrecision, RoundingQuestion } from '../../../common/verifyMathAritRoundingPrecision';

interface MathAritRoundingPreviewProps {
  questions: RoundingQuestion[];
}

const MathAritRoundingPreview: React.FC<MathAritRoundingPreviewProps> = ({ questions }) => {
  const [userAnswers, setUserAnswers] = useState<string[]>(Array(questions.length).fill(''));
  const [feedback, setFeedback] = useState<(boolean | null)[]>(Array(questions.length).fill(null));

  const handleInputChange = (value: string, index: number) => {
    const newAnswers = [...userAnswers];
    newAnswers[index] = value;
    setUserAnswers(newAnswers);
  };

  const checkAnswer = (index: number) => {
    const question = questions[index];
    const result = verifyMathAritRoundingPrecision(question, userAnswers[index]);

    const newFeedback = [...feedback];
    newFeedback[index] = result.isCorrect;
    setFeedback(newFeedback);
  };

  return (
    <Box mt={4}>
      <Typography variant="h5" mb={2}>
        Esikatselu
      </Typography>
      {questions.map((question, index) => (
        <Box key={index} mb={4} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h6" component="div">
            Pyöristä {question.numberToRound} tarkkuudella {10 ** question.precision} ({question.roundingMode})
          </Typography>
          <TextField
            value={userAnswers[index]}
            onChange={(e) => handleInputChange(e.target.value, index)}
            sx={{ width: '100px' }}
          />
          <Button variant="contained" color="primary" onClick={() => checkAnswer(index)}>
            Tarkista
          </Button>
          {feedback[index] !== null && (
            feedback[index] === true ? (
              <CheckCircleIcon sx={{ color: 'green' }} />
            ) : (
              <CancelIcon sx={{ color: 'red' }} />
            )
          )}
        </Box>
      ))}
    </Box>
  );
};

export default MathAritRoundingPreview;
