import api from './api';

// Function to synthesize speech using the backend API
export const synthesizeSpeech = async (uuid: string, text: string, voiceCode: string = 'en-US-JennyNeural') => {  
  try {
    // Send request with `voiceCode` instead of `language`
    const response = await api.post('/speech/synthesize', { text, voiceCode }, {
      responseType: 'blob', // Expecting a binary response (audio file)
    });

    console.log('******** Synthesized speech:', response.data);

    // Convert Blob to ArrayBuffer
    const arrayBuffer = await response.data.arrayBuffer();

    return arrayBuffer; // Return the arrayBuffer for further processing
  } catch (error) {
    console.error('Error synthesizing speech:', error);
    throw error; // Propagate the error to handle it on the calling side
  }
};

export const uploadAudioToBlob = async (audioBlob: ArrayBuffer, uuid: string) => {
  try {
    console.log('Audio data:', audioBlob);
    
    // Convert ArrayBuffer to Blob
    const blob = new Blob([audioBlob], { type: 'application/octet-stream' });
    
    // Create FormData to send the Blob
    const formData = new FormData();
    formData.append('file', blob, 'speech.wav'); // Append as a pseudo-file

    // Use Axios to send the form data
    const response = await api.post(`/speech/upload/${uuid}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure correct content type
      },
    });

    // Log the response from the server
    console.log('Audio uploaded. Result:', response.data);

    // Return the server response, which should include the URL of the uploaded blob
    return response.data;
  } catch (error) {
    console.error('Error uploading audio to Blob Storage:', error);
    throw error; // Propagate the error to handle it on the calling side
  }
};

// Function to download audio from Azure Blob Storage using the backend API
export const downloadAudioFromBlob = async (uuid: string) => {
  try {
    // Send a GET request to the backend to fetch the audio file from Azure Blob Storage
    const response = await api.get(`/speech/download/${uuid}`, { responseType: 'arraybuffer' });

    // Check if the response status is 204 No Content
    if (response.status === 204) {
      console.log(`No audio content found for UUID: ${uuid}`);
      return null; // Return null if no content is found
    }

    // Convert the response data (ArrayBuffer) to a Blob URL
    const audioBlob = new Blob([response.data], { type: 'audio/wav' });
    const audioUrl = URL.createObjectURL(audioBlob);
    console.log(`Audio downloaded from Blob Storage for UUID: ${uuid}`);

    return audioUrl; // Return the Blob URL for playback
  } catch (error) {
    console.error('Error downloading audio from Blob Storage:', error);
    throw error; // Propagate other errors to handle them on the calling side
  }
};

// Helper function to play audio from a Blob URL
export const playSpeechFromUrl = async (audioUrl: string) => {
  try {
    // Use the HTML5 Audio element for playing audio from URL
    const audio = new Audio(audioUrl);
    await audio.play();
    console.log('Audio played successfully from URL');
  } catch (error) {
    console.error('Error playing audio from URL:', error);
  }
};

// Helper function to play audio from ArrayBuffer using AudioContext
export const playSpeechFromArrayBuffer = async (audioArrayBuffer: ArrayBuffer) => {
  try {
    // Create a new AudioContext
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

    // Decode audio data from the ArrayBuffer
    const audioBuffer = await audioContext.decodeAudioData(audioArrayBuffer);

    // Create an AudioBufferSourceNode from the audio buffer
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;

    // Connect the source to the audio context's destination (the speakers)
    source.connect(audioContext.destination);

    // Start playing the audio
    source.start(0);
    console.log('Audio playing from ArrayBuffer');
  } catch (error) {
    console.error('Error playing speech from ArrayBuffer:', error);
  }
};

export const playSpeech = async (audioArrayBuffer: ArrayBuffer, onPlaySuccess: () => void, cacheKey: string) => {
  try {
    // Create a new AudioContext
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

    // Decode audio data from the ArrayBuffer
    const audioBuffer = await audioContext.decodeAudioData(audioArrayBuffer);

    // Create an AudioBufferSourceNode from the audio buffer
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;

    // Connect the source to the audio context's destination (the speakers)
    source.connect(audioContext.destination);

    // Start playing the audio
    source.start(0);

    // Call the success callback after starting playback
    onPlaySuccess();

    // Cache the audio only after successful playback
    console.log('Audio playing and cached successfully');
  } catch (error) {
    console.error('Error playing speech:', error);
  }
};
