import React, { useState } from 'react';
import { Box, TextField, Typography, IconButton, Divider, Checkbox, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SpeechButton from '../../common/SpeechButton';
import VoiceSelector from '../../common/VoiceSelector';

interface QuestionProps {
  question: {
    id: string;
    text: string;
    hint: string;
    answers: string[][];
    aiCheckEnabled: boolean;
    voiceCode?: string;
  };
  index: number;
  onQuestionChange: (index: number, updatedQuestion: any) => void;
  onRemoveQuestion: (index: number) => void;
}

const FillInBlanksQuestion: React.FC<QuestionProps> = ({ question, index, onQuestionChange, onRemoveQuestion }) => {
  const [hasChanged, setHasChanged] = useState(false);

  const handleVoiceSelect = (voice: { code: string }) => {
    // Update the question with the selected voice code
    onQuestionChange(index, { ...question, voiceCode: voice.code });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasChanged) setHasChanged(true);
    onQuestionChange(index, { ...question, text: e.target.value });
  };

  const handleAnswersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasChanged) setHasChanged(true);
    const answersArray = e.target.value.split('|').map(answerGroup => answerGroup.split(',').map(ans => ans.trim()));
    onQuestionChange(index, { ...question, answers: answersArray });
  };

  const handleHintChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasChanged) setHasChanged(true);
    onQuestionChange(index, { ...question, hint: e.target.value });
  };

  const handleAICheckChange = () => {
    if (!hasChanged) setHasChanged(true);
    onQuestionChange(index, { ...question, aiCheckEnabled: !question.aiCheckEnabled });
  };

  return (
    <Box mb={3} p={2} border={1} borderColor="grey.300" borderRadius={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Kysymys {index + 1}</Typography>
        <Box>
          {/* Speech Button with the selected voice code */}
          <SpeechButton 
            id={question.id} 
            text={question.text} 
            voiceCode={question.voiceCode || 'en-US-JennyNeural'} // Use voiceCode from question
          />
          <IconButton onClick={() => onRemoveQuestion(index)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Voice Selection using VoiceSelector */}
      <VoiceSelector onSelectVoice={handleVoiceSelect} initialVoiceCode={question.voiceCode} />

      <TextField
        label="Kysymysteksti"
        variant="outlined"
        fullWidth
        placeholder="Esim. 'Taivas on __.'"
        value={question.text}
        onChange={handleTextChange}
        margin="normal"
      />

      <TextField
        label="Vastaukset"
        variant="outlined"
        fullWidth
        placeholder="Erottele vastaukset | merkillä, esim. 'sininen|kirkas', ja monikko pilkulla"
        value={question.answers.map(answerGroup => answerGroup.join(',')).join(' | ')}
        onChange={handleAnswersChange}
        margin="normal"
      />

      <TextField
        label="Vihje"
        variant="outlined"
        fullWidth
        placeholder="Anna vihje"
        value={question.hint}
        onChange={handleHintChange}
        margin="normal"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={question.aiCheckEnabled}
            onChange={handleAICheckChange}
          />
        }
        label="AI-tarkistus käytössä"
      />
    </Box>
  );
};

export default FillInBlanksQuestion;
