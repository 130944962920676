import React from 'react';
import { Box, TextField, MenuItem, Typography, Button } from '@mui/material';

interface MathAritRoundingQuestionProps {
  question: {
    id: string;
    order: number;
    numberToRound: number;
    precision: number; // Rounding factor (e.g., 10 for tens, 100 for hundreds)
    roundingMode: 'up' | 'down' | 'nearest';
  };
  onUpdate: (updatedQuestion: MathAritRoundingQuestionProps['question']) => void;
}

const MathAritRoundingQuestion: React.FC<MathAritRoundingQuestionProps> = ({
  question,
  onUpdate,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({
      ...question,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box mb={4}>
      <Typography variant="h6">Tehtävä {question.order}</Typography>
      <Box display="flex" alignItems="center" gap={2}>
        <TextField
          label="Numero"
          type="number"
          name="numberToRound"
          value={question.numberToRound}
          onChange={handleInputChange}
        />
        <TextField
          select
          label="Pyöristyksen tarkkuus"
          name="precision"
          value={question.precision}
          onChange={handleInputChange}
        >
          <MenuItem value={0}>Ykköset</MenuItem>
          <MenuItem value={1}>Kymmenet</MenuItem>
          <MenuItem value={2}>Sataset</MenuItem>
          <MenuItem value={3}>Tuhannet</MenuItem>
        </TextField>
        <TextField
          select
          label="Pyöristys"
          name="roundingMode"
          value={question.roundingMode}
          onChange={handleInputChange}
        >
          <MenuItem value="nearest">Lähin</MenuItem>
          <MenuItem value="up">Ylös</MenuItem>
          <MenuItem value="down">Alas</MenuItem>
        </TextField>
        <Button
          variant="outlined"
          onClick={() =>
            onUpdate({
              ...question,
              numberToRound: Math.floor(Math.random() * 10000), // Generate a random number for demonstration
            })
          }
        >
          Randomisoi
        </Button>
      </Box>
    </Box>
  );
};

export default MathAritRoundingQuestion;
