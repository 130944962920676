import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { verifyFillInBlanks, Question, ExerciseConfig } from '../../../common/verifyFillInBlanks';
import SpeechButton from '../../common/SpeechButton';

interface QuestionStudentProps {
  question: Question;
  answer: string;
  onAnswerChange: (questionId: string, answer: string) => void;
  feedback?: { isCorrect: boolean };
  disabled: boolean;
}

const QuestionStudent: React.FC<QuestionStudentProps> = ({
  question,
  answer,
  onAnswerChange,
  feedback,
  disabled
}) => {
  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAnswerChange(question.id, e.target.value);
  };

  const answerBox = (
    <TextField
      variant="outlined"
      size="small"
      sx={{ width: '100px', marginX: 1 }}
      value={answer}
      onChange={handleAnswerChange}
      disabled={disabled}
    />
  );

  const formattedText = question.text.split('__').reduce((acc, part, index, array) => {
    acc.push(<span key={`text-${index}`}>{part}</span>);
    if (index < array.length - 1) {
      acc.push(<span key={`input-${index}`}>{answerBox}</span>);
    }
    return acc;
  }, [] as React.ReactNode[]);

  return (
    <Box mb={2} display="flex" alignItems="center">
      <Typography variant="body1" component="span">
        {formattedText}
      </Typography>
      {feedback && (
        <Box ml={1}>
          {feedback.isCorrect ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
        </Box>
      )}
      <SpeechButton 
        id={question.id} 
        text={question.text.replace(/__/g, answer)} 
        voiceCode={question.voiceCode || 'en-US-JennyNeural'} // Use question's voiceCode or default to Jenny
      />
      <Typography variant="caption" color="textSecondary" display="block" ml={2}>
        <em>Hint: {question.hint}</em>
      </Typography>
    </Box>
  );
};

interface FillInBlanksStudentProps {
  config: ExerciseConfig;
  initialAnswers: { [key: string]: string };
  disabled: boolean;
  onAnswerChange: (questionId: string, answer: string) => void;
  assignmentStatus: 'New' | 'Active' | 'InReview';
  hasSubmitted: boolean;
}

const FillInBlanksStudent: React.FC<FillInBlanksStudentProps> = ({
  config,
  initialAnswers,
  disabled,
  onAnswerChange,
  assignmentStatus,
  hasSubmitted,
}) => {
  console.log('Render FillInBlanksStudent');
  const [userAnswers, setUserAnswers] = useState<{ [key: string]: string }>(initialAnswers);
  const [feedback, setFeedback] = useState<{ questionId: string; isCorrect: boolean }[]>([]);

  useEffect(() => {
    setUserAnswers(initialAnswers); // Reset answers if initial answers change
  }, [initialAnswers]);

  useEffect(() => {
    // Calculate feedback when the assignment is in review or after submission
    if ((assignmentStatus === 'InReview' || hasSubmitted) && config.questions.length) {
      const verificationResults = verifyFillInBlanks(
        Object.entries(userAnswers).map(([questionId, answer]) => ({ questionId, answer })),
        config
      );
      setFeedback(verificationResults);
    }
  }, [userAnswers, assignmentStatus, hasSubmitted, config]);

  const handleAnswerChange = (questionId: string, answer: string) => {
    setUserAnswers(prev => ({ ...prev, [questionId]: answer }));
    onAnswerChange(questionId, answer);
  };

  const shouldShowFeedback = assignmentStatus === 'InReview' || hasSubmitted;

  return (
    <Box mt={2}>
      {config.questions.map((question) => {
        const feedbackItem = feedback.find(f => f.questionId === question.id);
        return (
          <QuestionStudent
            key={question.id}
            question={question}
            answer={userAnswers[question.id] || ''}
            onAnswerChange={handleAnswerChange}
            feedback={shouldShowFeedback && feedbackItem ? { isCorrect: feedbackItem.isCorrect } : undefined}
            disabled={assignmentStatus === 'InReview' || hasSubmitted}
          />
        );
      })}
    </Box>
  );
};

export default FillInBlanksStudent;
