import api from './api';

export const getExercises = async (lessonId: number) => {
  const response = await api.get(`/lesson/${lessonId}/exercise`);
  return response.data;
};

export const getExercise = async (lessonId: number, exerciseId: number) => {
  const response = await api.get(`/lesson/${lessonId}/exercise/${exerciseId}`);
  return response.data;
};

export const createExercise = async (lessonId: number, exercise: { type: string; config: string; instructions?: string; maxScore: number; order: number; additionalInfo?: string; }) => {
  const response = await api.post(`/lesson/${lessonId}/exercise`, exercise);
  return response.data;
};

export const updateExercise = async (lessonId: number, exerciseId: number, exercise: { type: string; config: string; instructions?: string; maxScore: number; order: number; additionalInfo?: string; }) => {
  const response = await api.put(`/lesson/${lessonId}/exercise/${exerciseId}`, exercise);
  return response.data;
};

export const deleteExercise = async (lessonId: number, exerciseId: number) => {
  const response = await api.delete(`/lesson/${lessonId}/exercise/${exerciseId}`);
  return response.data;
};
