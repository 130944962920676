import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { fixText, translateText } from '../services/aiExerciseService';

interface HistoryItem {
  type: 'correction' | 'translation';
  text: string;
  date: string;
}

const TextFixerPage: React.FC = () => {
  const [inputText, setInputText] = useState<string>('');
  const [fixedText, setFixedText] = useState<string | null>(null);
  const [translatedText, setTranslatedText] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<string | null>(null);
  const [history, setHistory] = useState<HistoryItem[]>([]);

  // Load history from local storage
  useEffect(() => {
    const savedHistory = localStorage.getItem('textFixerHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Save history to local storage whenever it updates
  useEffect(() => {
    localStorage.setItem('textFixerHistory', JSON.stringify(history.slice(0, 20))); // Keep only the latest 20
  }, [history]);

  const handleFixText = async () => {
    if (!inputText.trim()) return;

    setIsLoading(true);
    try {
      const response = await fixText(inputText);
      const result = response.data;
      setFixedText(result);
      setHistory([
        { type: 'correction', text: result, date: new Date().toLocaleString() },
        ...history,
      ]);
    } catch (error) {
      console.error('Error fixing text:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTranslateText = async () => {
    if (!inputText.trim()) return;

    setIsLoading(true);
    try {
      const response = await translateText(inputText);
      const result = response.data;
      setTranslatedText(result);
      setHistory([
        { type: 'translation', text: result, date: new Date().toLocaleString() },
        ...history,
      ]);
    } catch (error) {
      console.error('Error translating text:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearText = () => {
    setInputText('');
    setFixedText(null);
    setTranslatedText(null);
    setCopySuccess(null);
  };

  const handleCopyText = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess('Teksti kopioitu!');
      setTimeout(() => setCopySuccess(null), 2000);
    } catch (error) {
      console.error('Failed to copy text:', error);
      setCopySuccess('Kopiointi epäonnistui.');
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', p: 3, border: 1, borderColor: 'lightgray', borderRadius: 2 }}>
      <Typography variant="h5" gutterBottom>
        Korjaus ja Käännös
      </Typography>
      <TextField
        label="Syötä teksti"
        variant="outlined"
        fullWidth
        multiline
        minRows={4}
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Box display="flex" gap={2} mb={3}>
        <Button variant="contained" color="primary" onClick={handleFixText} disabled={isLoading}>
          {isLoading ? 'Käsitellään...' : 'Korjaa teksti'}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleTranslateText} disabled={isLoading}>
          {isLoading ? 'Käsitellään...' : 'Käännä suomeksi'}
        </Button>
        <Button variant="outlined" color="error" onClick={handleClearText}>
          Tyhjennä teksti
        </Button>
      </Box>

      {fixedText && (
        <Box>
          <Typography variant="h6">Korjattu teksti</Typography>
          <Box
            sx={{
              mt: 2,
              p: 2,
              border: 1,
              borderColor: 'lightgray',
              borderRadius: 1,
              backgroundColor: '#f9f9f9',
            }}
          >
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
              {fixedText}
            </Typography>
            <IconButton onClick={() => handleCopyText(fixedText)} title="Kopioi teksti">
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </Box>
      )}

      {translatedText && (
        <Box mt={3}>
          <Typography variant="h6">Käännetty teksti</Typography>
          <Box
            sx={{
              mt: 2,
              p: 2,
              border: 1,
              borderColor: 'lightgray',
              borderRadius: 1,
              backgroundColor: '#f9f9f9',
            }}
          >
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
              {translatedText}
            </Typography>
            <IconButton onClick={() => handleCopyText(translatedText)} title="Kopioi teksti">
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </Box>
      )}

      {copySuccess && (
        <Typography variant="body2" color="success.main" sx={{ mt: 2, textAlign: 'right', fontStyle: 'italic' }}>
          {copySuccess}
        </Typography>
      )}

      {/* History Section */}
      <Box mt={4}>
        <Typography variant="h6">Viimeisimmät (20)</Typography>
        {history.map((item, index) => (
          <Box
            key={index}
            sx={{
              mt: 2,
              p: 2,
              border: 1,
              borderColor: 'lightgray',
              borderRadius: 1,
              backgroundColor: item.type === 'correction' ? '#e3f2fd' : '#fce4ec',
              position: 'relative',
            }}
          >
            <Typography variant="caption" color="textSecondary" gutterBottom>
              {item.date}
            </Typography>
            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
              {item.text}
            </Typography>
            <IconButton
              onClick={() => handleCopyText(item.text)}
              sx={{ position: 'absolute', top: 10, right: 10 }}
              title="Kopioi teksti"
            >
              <ContentCopyIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TextFixerPage;
