// src/components/Course/CourseForm.tsx
import React, { useState } from 'react';
import { TextField, Button, Container, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

const CourseForm: React.FC<{ initialData?: { name: string; description: string; startDate: string; endDate: string; subject: string; subjectSpecification: string; }, onSubmit: (data: { name: string; description: string; startDate: string; endDate: string; subject: string; subjectSpecification: string; }) => void }> = ({ initialData, onSubmit }) => {
  const [formData, setFormData] = useState(initialData || { name: '', description: '', startDate: '', endDate: '', subject: '', subjectSpecification: '' });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Container maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Nimi"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          label="Kuvaus"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          multiline
          rows={4}
          fullWidth
        />
        <TextField
          label="Aloituspäivä"
          type="date"
          name="startDate"
          value={formData.startDate}
          onChange={handleChange}
          required
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label="Lopetuspäivä"
          type="date"
          name="endDate"
          value={formData.endDate}
          onChange={handleChange}
          required
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
        <TextField
          label="Aine"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          label="Aineen tarkenne"
          name="subjectSpecification"
          value={formData.subjectSpecification}
          onChange={handleChange}
          required
          fullWidth
        />
        <Button variant="contained" color="primary" type="submit" startIcon={<SaveIcon />}>
          Tallenna
        </Button>
      </Box>
    </Container>
  );
};

export default CourseForm;
