import api from './api';

export const getLessons = async (courseId: number) => {
  const response = await api.get(`/course/${courseId}/lesson`);
  return response.data;
};

export const getLesson = async (courseId: number, lessonId: number) => {
  const response = await api.get(`/course/${courseId}/lesson/${lessonId}`);
  return response.data;
};

export const createLesson = async (courseId: number, lesson: { title: string; description: string; order: number; isActive: boolean; }) => {
  const response = await api.post(`/course/${courseId}/lesson`, lesson);
  return response.data;
};

export const updateLesson = async (courseId: number, lessonId: number, lesson: { title: string; description: string; order: number; isActive: boolean; }) => {
  const response = await api.put(`/course/${courseId}/lesson/${lessonId}`, lesson);
  return response.data;
};

export const deleteLesson = async (courseId: number, lessonId: number) => {
  const response = await api.delete(`/course/${courseId}/lesson/${lessonId}`);
  return response.data;
};
