import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useAuth } from './AuthContext';

const Login: React.FC = () => {
  const { user, setUser } = useAuth();

  const responseGoogle = async (response: any) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/google`, {
        token: response.credential,
      });
      // Store the JWT, refresh token, and user info in local storage
      localStorage.setItem('token', res.data.accessToken);
      localStorage.setItem('refreshToken', res.data.refreshToken);
      localStorage.setItem('user', JSON.stringify({
        name: res.data.name,
        email: res.data.email,
        picture: res.data.picture,
      }));
      setUser({
        name: res.data.name,
        email: res.data.email,
        picture: res.data.picture,
        role: res.data.role,
      });
      window.location.href = '/'; // Redirect to the home page or any other page after login
    } catch (error) {
      console.log(error);
    }
  };

  if (user) {
    return null; // If user is logged in, don't show the Google Login button
  }

  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  if (!GOOGLE_CLIENT_ID) {
    throw new Error('REACT_APP_GOOGLE_CLIENT_ID is not set');
  }

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <GoogleLogin
        onSuccess={responseGoogle}
        onError={() => {
          console.log('Kirjautuminen epäonnistui');
        }}
        useOneTap
      />
    </GoogleOAuthProvider>
  );
};

export default Login;
