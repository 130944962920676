import React, { useState } from 'react';
import { Card, CardContent, Typography, CardActions, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Exercise } from '../../types/interfaces'; // Import the Exercise type
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Import an icon for active/inactive status

interface ExerciseItemProps {
  exercise: Exercise;
  onDelete: (id: number) => void;
  courseId: string; // Add courseId as a prop
}

const ExerciseItem: React.FC<ExerciseItemProps> = ({ exercise, onDelete, courseId }) => {
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    await onDelete(exercise.id);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Order Number */}
        <Typography variant="h3" component="div" sx={{ mr: 3 }}>
          {exercise.order}
        </Typography>

        {/* Exercise Instructions */}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
            {exercise.instructions}
          </Typography>
        </Box>

        {/* Active/Inactive Indicator */}
        <FiberManualRecordIcon 
          sx={{ color: exercise.maxScore > 0 ? 'green' : 'gray' }} 
          titleAccess={exercise.maxScore > 0 ? 'Aktiivinen' : 'Ei aktiivinen'}
        />
      </CardContent>
      <CardActions>
        {/* Use courseId in the RouterLink paths */}
        <Button size="small" component={RouterLink} to={`/course/${courseId}/lesson/${exercise.lessonId}/exercise/${exercise.id}`}>
          Näytä
        </Button>
        <Button size="small" component={RouterLink} to={`/course/${courseId}/lesson/${exercise.lessonId}/exercise/edit/${exercise.id}`}>
          Muokkaa
        </Button>
        {/* Add the Assign button */}
        <Button size="small" component={RouterLink} to={`/course/${courseId}/lesson/${exercise.lessonId}/exercise/assign/${exercise.id}`}>
          Määrää tehtävä
        </Button>
        <Button size="small" color="secondary" onClick={handleClickOpen}>
          Poista
        </Button>
      </CardActions>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Vahvista poisto"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oletko varma että haluat poistaa harjoituksen {exercise.order}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Peruuta
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Poista
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ExerciseItem;
