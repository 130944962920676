// src/pages/Exercise/ExerciseTypeSelection.tsx
import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';

interface ExerciseTypeSelectionProps {
  exerciseTypes: {
    exerciseTypeCode: string;
    exerciseTypeName: string;
    pictureFileName: string;
  }[];
  onSelectType: (typeCode: string) => void;
}

const ExerciseTypeSelection: React.FC<ExerciseTypeSelectionProps> = ({ exerciseTypes, onSelectType }) => {
  return (
    <Box mt={3}>
      <Typography variant="h5" gutterBottom>
        Valitse harjoituksen tyyppi
      </Typography>
      <Grid container spacing={3}>
        {exerciseTypes.map((type) => (
          <Grid item xs={12} sm={6} md={4} key={type.exerciseTypeCode}>
            <Card
              sx={{ cursor: 'pointer' }}
              onClick={() => onSelectType(type.exerciseTypeCode)}
            >
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2,
                  }}
                >
                  <img
                    src={`/images/${type.pictureFileName}`} // Ensure images are stored in public/images
                    alt={type.exerciseTypeName}
                    style={{ maxHeight: '100px', maxWidth: '100%' }}
                  />
                </Box>
                <Typography variant="h6" textAlign="center">
                  {type.exerciseTypeName}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ExerciseTypeSelection;
