import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import MathAritRoundingQuestion from './MathAritRoundingQuestion';
import MathAritRoundingPreview from './MathAritRoundingPreview';
import { v4 as uuidv4 } from 'uuid';

interface RoundingQuestion {
  id: string;
  order: number;
  numberToRound: number;
  precision: number; // Number of decimal places or rounding factor (e.g., 10 for tens, 100 for hundreds)
  roundingMode: 'up' | 'down' | 'nearest';
}

interface ConfigProps {
  onConfigChange: (config: any) => void;
  initialConfig?: { questions: RoundingQuestion[] };
}

const MathAritRoundingConfig: React.FC<ConfigProps> = ({ onConfigChange, initialConfig }) => {
  const [questions, setQuestions] = useState<RoundingQuestion[]>(initialConfig?.questions || []);

  useEffect(() => {
    onConfigChange({ questions });
  }, [questions, onConfigChange]);

  const addQuestion = () => {
    const newOrder = questions.length + 1;
    setQuestions([
      ...questions,
      {
        id: uuidv4(),
        order: newOrder,
        numberToRound: 0,
        precision: 0,
        roundingMode: 'nearest',
      },
    ]);
  };

  const updateQuestion = (updatedQuestion: RoundingQuestion) => {
    setQuestions(questions.map((q) => (q.order === updatedQuestion.order ? updatedQuestion : q)));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Pyöristämistehtävien asetukset
      </Typography>
      {questions.map((question) => (
        <MathAritRoundingQuestion key={question.order} question={question} onUpdate={updateQuestion} />
      ))}
      <Button variant="contained" color="primary" onClick={addQuestion} sx={{ mb: 2 }}>
        Lisää uusi tehtävä
      </Button>      

      <Box mt={4}>
        <MathAritRoundingPreview questions={questions} />
      </Box>
    </Box>
  );
};

export default MathAritRoundingConfig;

