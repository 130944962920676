// ScoreContext.tsx
import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { getUserScoreByEmail } from '../services/assignmentService'; // Service to fetch score
import { useAuth } from './AuthContext'; // Authentication context for user data
import { UserRole } from '../common/enums';

interface ScoreContextProps {
  score: number | null;
  refreshScore: () => void;
}

const ScoreContext = createContext<ScoreContextProps | undefined>(undefined);

export const ScoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [score, setScore] = useState<number | null>(null);

  // Fetch the user's score
  const fetchUserScore = useCallback(async () => {
    console.log('Fetching user score...');
    console.log('User:', user);
    if (user && user.role === UserRole.Student) {
      try {
        console.log('Fetching score for user:', user.email);
        const response = await getUserScoreByEmail(user.email);
        setScore(response.data.totalScore); // Ensure the score is set correctly
        console.log('User score:', response.data.totalScore);
      } catch (error) {
        console.error('Error fetching user score:', error);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchUserScore(); // Fetch the score when the component mounts or when the user changes
  }, [fetchUserScore]);

  // Function to manually refresh the score
  const refreshScore = () => {
    console.log('Refreshing score...');
    fetchUserScore(); // Trigger fetching the score again
  };

  return (
    <ScoreContext.Provider value={{ score, refreshScore }}>
      {children}
    </ScoreContext.Provider>
  );
};

// Hook to use the Score Context
export const useScore = () => {
  const context = useContext(ScoreContext);
  if (!context) {
    throw new Error('useScore must be used within a ScoreProvider');
  }
  return context;
};
