import React, { useState } from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ExerciseCategorySelection from '../../components/Exercise/ExerciseCategorySelection';
import ExerciseTypeSelection from '../../components/Exercise/ExerciseTypeSelection';
import ExerciseCommonFields from '../../components/Exercise/ExerciseCommonFields';
import MathArit2Operand1PhaseConfig from '../../components/Exercise/MathArit2Operand1Phase/MathArit2Operand1PhaseConfig';
import exerciseCategories from '../../types/exercise.category.json';
import exerciseTypesData from '../../types/exercise.type.json';
import { createExercise } from '../../services/exerciseService'; // Import createExercise service
import Breadcrumb from '../../components/Breadcrumb';
import MathAritRoundingConfig from '../../components/Exercise/MathAritRounding/MathAritRoundingConfig';
import FillInBlanksConfig from '../../components/Exercise/FillInTheBlanks/FillInBlanksConfig';
import PairingConfig from '../../components/Exercise/Pairing/PairingConfig';
import MaterialBasedConfig from '../../components/Exercise/MaterialBased/MaterialBasedConfig';
import SortToBoxesConfig from '../../components/Exercise/SortToBoxes/SortToBoxesConfig';

const ExerciseCreatePage: React.FC = () => {
  const { lessonId } = useParams<{ lessonId: string }>();
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [exerciseConfig, setExerciseConfig] = useState<any>(null);

  // Common exercise fields state
  const [commonFields, setCommonFields] = useState({
    instructions: '',
    additionalInfo: '',
    order: 1,
    maxScore: 100,
  });

  const handleSelectCategory = (category: string) => {
    setSelectedCategory(category);
    setSelectedType(null); // Reset type selection when category changes
  };

  const handleSelectType = (typeCode: string) => {
    setSelectedType(typeCode);
  };

  const handleCommonFieldChange = (field: string, value: any) => {
    setCommonFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleConfigChange = (config: any) => {
    setExerciseConfig(config);
  };

  const handleSaveExercise = async () => {
    if (!lessonId || !selectedType || !exerciseConfig) return;

    const exerciseData = {
      type: selectedType,
      config: JSON.stringify(exerciseConfig), // Convert config to string
      instructions: commonFields.instructions,
      additionalInfo: commonFields.additionalInfo,
      order: commonFields.order,
      maxScore: commonFields.maxScore,
    };

    try {
      await createExercise(parseInt(lessonId, 10), exerciseData);
      navigate(`/course/${courseId}/lesson/${lessonId}`); // Navigate back to the lesson page
    } catch (error) {
      console.error('Failed to save exercise:', error);
    }
  };

  const renderExerciseConfig = () => {
    switch (selectedType) {
      case 'math_arit_2operand_1phase':
        return <MathArit2Operand1PhaseConfig onConfigChange={handleConfigChange} />;
      case 'math_arit_rounding_precision':
        return <MathAritRoundingConfig onConfigChange={handleConfigChange} />;
      case 'math_arit_2operand_2phase':
        return <MathArit2Operand1PhaseConfig onConfigChange={handleConfigChange} />;
      case 'fill_in_the_blanks':
        return <FillInBlanksConfig onConfigChange={handleConfigChange} />;
      case 'pairing':
        return <PairingConfig onConfigChange={handleConfigChange} />;
      case 'material_based_ai_assisted':
        return <MaterialBasedConfig onConfigChange={handleConfigChange} />;
      case 'sort_to_boxes':
        return <SortToBoxesConfig onConfigChange={handleConfigChange} />;
      default:
        return <Typography variant="body1">Valitse harjoituksen tyyppi</Typography>;
    }
  };

  return (
    <Container>
      {/* Breadcrumb Navigation */}
      {courseId && <Breadcrumb courseId={courseId} lessonId={lessonId} additionalCrumb="Uusi harjoitus *" />}
      <Box mt={3}>
        <Typography variant="h4" gutterBottom>
          Luo uusi harjoitus
        </Typography>

        {/* Exercise Category Selection */}
        <Box mb={4}>
          <ExerciseCategorySelection
            categories={exerciseCategories}
            onSelectCategory={handleSelectCategory}
          />
        </Box>

        {/* Exercise Type Selection */}
        {selectedCategory && (
          <Box mb={4}>
            <ExerciseTypeSelection
              exerciseTypes={exerciseTypesData.filter(
                (type) => type.exerciseCategory === selectedCategory
              )}
              onSelectType={handleSelectType}
            />
          </Box>
        )}

        {/* Exercise Common Fields */}
        {selectedType && (
          <Box mb={4}>
            <ExerciseCommonFields
              instructions={commonFields.instructions}
              additionalInfo={commonFields.additionalInfo}
              order={commonFields.order}
              maxScore={commonFields.maxScore}
              onChange={handleCommonFieldChange}
            />
          </Box>
        )}

        {/* Exercise Configuration */}
        {selectedType && (
          <Box mb={4}>
            {renderExerciseConfig()}
          </Box>
        )}

        {/* Save Button */}
        {/* Save Exercise Button */}
        {selectedType && (
          <Box mb={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveExercise}
            >
              Tallenna harjoitus
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ExerciseCreatePage;
