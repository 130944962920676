import React, { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, TextField, Checkbox, FormControl, FormControlLabel, Typography, Divider } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FillInBlanksQuestion from './FillInBlanksQuestion';
import FillInBlanksPreview from './FillInBlanksPreview';

interface QuestionConfig {
  id: string;
  text: string;
  answers: string[][];
  hint: string;
  aiCheckEnabled: boolean;
  voiceCode?: string;  
}

interface FillInBlanksConfigProps {
  initialConfig?: any;
  onConfigChange: (config: any) => void;
}

const FillInBlanksConfig: React.FC<FillInBlanksConfigProps> = ({ initialConfig = {}, onConfigChange }) => {
  console.log('Render FillInBlanksConfig');

  const [questions, setQuestions] = useState<QuestionConfig[]>(initialConfig.questions || []);
  const [language, setLanguage] = useState(initialConfig.language || 'fi');
  const [isContinuous, setIsContinuous] = useState(initialConfig.isContinuous || false);
  const [useTextToSpeech, setUseTextToSpeech] = useState(initialConfig.useTextToSpeech || false);

  const handleConfigChange = useCallback(() => {
    const config = {
      exerciseType: 'fill_in_the_blanks',
      language,
      isContinuous,
      useTextToSpeech,
      questions,
    };
    onConfigChange(config);
  }, [language, isContinuous, useTextToSpeech, questions, onConfigChange]);

  useEffect(() => {
    handleConfigChange();
  }, [handleConfigChange]);

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { id: uuidv4(), text: '', answers: [[]], hint: '', aiCheckEnabled: false, voiceCode: 'en-US-JennyNeural' },
    ]);
  };

  const updateQuestion = (index: number, updatedQuestion: QuestionConfig) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...updatedQuestion, aiCheckEnabled: updatedQuestion.aiCheckEnabled ?? false };
    setQuestions(updatedQuestions);
  };

  const removeQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Tyhjän kohdan täyttö - Asetukset</Typography>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Kieli"
          variant="outlined"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        />
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={isContinuous}
            onChange={() => setIsContinuous(!isContinuous)}
          />
        }
        label="Onko jatkoa"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={useTextToSpeech}
            onChange={() => setUseTextToSpeech(!useTextToSpeech)}
          />
        }
        label="Käytä tekstistä puheeksi"
      />

      <Box mt={2} mb={2}>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={addQuestion}
        >
          Lisää kysymys
        </Button>
      </Box>

      <Divider sx={{ my: 2 }} />

      {questions.map((question, index) => (
        <FillInBlanksQuestion
          key={question.id}
          index={index}
          question={question} // Pass the question object that includes voiceCode
          onQuestionChange={updateQuestion}
          onRemoveQuestion={removeQuestion}
        />
      ))}

      <Box mt={4}>
        <Typography variant="h6">Esikatselu</Typography>
        <FillInBlanksPreview
          config={{
            questions: questions,
            isContinuous,
          }}
        />
      </Box>
    </Box>
  );
};

export default FillInBlanksConfig;
