// src/pages/Exercise/ExerciseCategorySelection.tsx
import React from 'react';
import { Button, Grid, Box, Typography } from '@mui/material';

interface ExerciseCategorySelectionProps {
  categories: { key: string; value: string }[];
  onSelectCategory: (category: string) => void;
}

const ExerciseCategorySelection: React.FC<ExerciseCategorySelectionProps> = ({ categories, onSelectCategory }) => {
  return (
    <Box mt={3}>
      <Typography variant="h5" gutterBottom>
        Valitse harjoituksen kategoria
      </Typography>
      <Grid container spacing={2}>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} md={4} key={category.key}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => onSelectCategory(category.key)}
              sx={{
                height: 100,
                fontSize: '1.2rem',
                textTransform: 'none',
              }}
            >
              {category.value}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ExerciseCategorySelection;
