import React, { useEffect, useState, useCallback } from 'react';
import { Card, Typography, Box, CircularProgress, Avatar } from '@mui/material';
import { getLatestAssignmentsByStudent } from '../../services/assignmentService';
import { useAuth } from '../AuthContext';
import { Assignment } from '../../types/interfaces';
import { styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import { useNavigate } from 'react-router-dom';

const AssignmentCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const AssignmentDetails = styled(Box)({
  marginLeft: '16px',
  flex: 1,
});

const AssignmentCardContent: React.FC<{ assignment: Assignment }> = ({ assignment }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/assignment/${assignment.id}`);
  };

  return (
    <AssignmentCard onClick={handleCardClick}>
      <Avatar sx={{ bgcolor: 'primary.main' }}>
        <SchoolIcon />
      </Avatar>
      <AssignmentDetails>
        <Typography variant="h6" gutterBottom>
          {assignment.exercise?.order}. {assignment.exercise?.instructions}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <EventIcon fontSize="small" sx={{ verticalAlign: 'middle', marginRight: 0.5 }} />
          Oppitunti: {assignment.exercise?.lesson.title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Eräpäivä: {new Date(assignment.dueDate ?? new Date()).toLocaleDateString()}
        </Typography>
      </AssignmentDetails>
    </AssignmentCard>
  );
};

const LatestAssignments: React.FC = () => {
  const { user } = useAuth();
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchAssignments = useCallback(async () => {
    if (user) {
      try {
        const response = await getLatestAssignmentsByStudent(user.email);
        setAssignments(response.data);
        setError(null);
      } catch (err) {
        setError('Failed to fetch assignments');
        console.error('Failed to fetch assignments:', err);
      } finally {
        setLoading(false);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchAssignments();
  }, [fetchAssignments]);

  if (loading) {
    return (
      <Box mt={3} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box mt={3}>
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt={3}>
      <Typography variant="h5" gutterBottom>
        Viimeisimmät tehtävät
      </Typography>
      {assignments.length > 0 ? (
        assignments.map((assignment) => (
          <AssignmentCardContent key={assignment.id} assignment={assignment} />
        ))
      ) : (
        <Typography variant="body2">No assignments found.</Typography>
      )}
    </Box>
  );
};

export default LatestAssignments;
