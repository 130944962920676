// src/services/userService.ts
import api from './api';

export const getUser = async (email: string) => {
  const response = await api.get(`/user/${email}`);
  return response.data;
};

export const getAllUsers = async () => {
  const response = await api.get('/user');
  return response.data;
};

export const getAllStudents = async () => {
  const response = await api.get('/user/students/all');
  return response.data;
};
