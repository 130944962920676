import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface Container {
  id: string;
  name: string;
}

interface Term {
  id: string;
  text: string;
  containerId: string;
}

interface SortToBoxesPreviewProps {
  config: {
    containers: Container[];
    terms: Term[];
  };
}

const DraggableTerm: React.FC<{ term: Term }> = ({ term }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TERM',
    item: { id: term.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Paper
      ref={drag}
      sx={{
        padding: '8px',
        margin: '8px',
        width: '100px',
        textAlign: 'center',
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
      elevation={2}
    >
      {term.text}
    </Paper>
  );
};

interface DroppableContainerProps {
  container: Container;
  terms: Term[];
}

const DroppableContainer: React.FC<DroppableContainerProps> = ({
  container,
  terms,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'TERM',
    drop: (item: { id: string }) => {
      // This is a preview, so we don't update state here
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <Paper
      ref={drop}
      sx={{
        width: '200px',
        minHeight: '150px',
        margin: '16px',
        padding: '8px',
        backgroundColor: isOver ? 'lightgreen' : 'white',
      }}
      elevation={3}
    >
      <Typography variant="h6" align="center">
        {container.name}
      </Typography>
      {/* Terms will be dropped here in the student view */}
      {terms.map((term) => (
        <Paper
          key={term.id}
          sx={{
            padding: '4px',
            margin: '4px',
            textAlign: 'center',
          }}
          elevation={1}
        >
          {term.text}
        </Paper>
      ))}
    </Paper>
  );
};

const SortToBoxesPreview: React.FC<SortToBoxesPreviewProps> = ({ config }) => {
  const { containers, terms } = config;

  // Group terms by their containerId
  const termsByContainer: { [key: string]: Term[] } = {};
  terms.forEach((term) => {
    if (!termsByContainer[term.containerId]) {
      termsByContainer[term.containerId] = [];
    }
    termsByContainer[term.containerId].push(term);
  });

  // Terms that are not assigned to any container
  const unassignedTerms = terms.filter((term) => !term.containerId);

  return (
    <DndProvider backend={HTML5Backend}>
      <Box mt={2}>
        <Typography variant="subtitle1" gutterBottom>
          Opiskelijan näkymä
        </Typography>
        <Box display="flex" flexWrap="wrap" justifyContent="center" mb={4}>
          {containers.map((container) => (
            <DroppableContainer
              key={container.id}
              container={container}
              terms={termsByContainer[container.id] || []}
            />
          ))}
        </Box>
        <Box>
          <Typography variant="subtitle1">Termit:</Typography>
          <Box display="flex" flexWrap="wrap">
            {unassignedTerms.map((term) => (
              <DraggableTerm key={term.id} term={term} />
            ))}
          </Box>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default SortToBoxesPreview;
