// src/services/courseService.ts
import api from './api';

export const getCourses = async () => {
  const response = await api.get('/course');
  return response.data;
};

export const getCourse = async (id: number) => {
  const response = await api.get(`/course/${id}`);
  return response.data;
};

export const createCourse = async (course: { name: string; description: string; startDate: string; endDate: string; subject: string; subjectSpecification: string; }) => {
  const response = await api.post('/course', course);
  return response.data;
};

export const updateCourse = async (id: number, course: { name: string; description: string; startDate: string; endDate: string;  subject: string; subjectSpecification: string; }) => {
  const response = await api.put(`/course/${id}`, course);
  return response.data;
};

export const deleteCourse = async (id: number) => {
  const response = await api.delete(`/course/${id}`);
  return response.data;
};
