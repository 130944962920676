// src/pages/Courses/CourseCreatePage.tsx
import React from 'react';
import CourseForm from '../../components/Course/CourseForm';
import { createCourse } from '../../services/courseService';
import { useNavigate } from 'react-router-dom';

const CourseCreatePage: React.FC = () => {
  const navigate = useNavigate();

  const handleCreate = async (data: { name: string; description: string; startDate: string; endDate: string; subject: string; subjectSpecification: string }) => {
    await createCourse(data);
    navigate('/course');
  };

  return (
    <div>
      <h1>Luo kurssi</h1>
      <CourseForm onSubmit={handleCreate} />
    </div>
  );
};

export default CourseCreatePage;
