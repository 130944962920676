import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import LessonDetails from '../../components/Lesson/LessonDetails';
import Breadcrumb from '../../components/Breadcrumb';
import { getLesson } from '../../services/lessonService';
import { Lesson } from '../../types/interfaces';
import { Container, CircularProgress, Typography, Button, Box } from '@mui/material';
import ExerciseList from '../../components/Exercise/ExerciseList';
import AddIcon from '@mui/icons-material/Add';

const LessonViewPage: React.FC = () => {
  const { courseId, lessonId } = useParams<{ courseId: string; lessonId: string }>();
  const [lesson, setLesson] = useState<Lesson | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (courseId && lessonId) {
        const response = await getLesson(parseInt(courseId, 10), parseInt(lessonId, 10));
        setLesson(response.data);
      }
      setLoading(false);
    };

    fetchData();
  }, [courseId, lessonId]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!lesson) {
    return (
      <Container>
        <Typography variant="h5" component="div">
          Oppituntia ei löytynyt
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      {/* Breadcrumb Navigation */}
      {courseId && lessonId && <Breadcrumb courseId={courseId} lessonId={lessonId} />}

      {/* Lesson Details */}
      <Box mb={3}>
        <LessonDetails lesson={lesson} />
      </Box>

      {/* Exercise Creation Button */}
      <Box mb={4}>
        {lessonId && (
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`/course/${courseId}/lesson/${lessonId}/exercise/create`}
            startIcon={<AddIcon />}
          >
            Lisää uusi harjoitus
          </Button>
        )}
      </Box>

      {/* Exercise List */}
      <Box mb={6}>
        {lessonId && courseId && <ExerciseList lessonId={lessonId} courseId={courseId} />}
      </Box>
    </Container>
  );
};

export default LessonViewPage;
