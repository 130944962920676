import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid'; // Import UUID for unique identifiers
import QuestionComponent from './MathArit2Operand1PhaseQuestion';
import PreviewComponent from './MathArit2Operand1PhasePreview';

interface Question {
  id: string; // Unique identifier
  order: number;
  operand1: number;
  operand2: number;
  operator: '+' | '-' | '*' | '/';
  randomizeOperands: boolean;
  randomizeAll: boolean;
  digitCount: number; // Number of digits for randomizing
}

interface ConfigProps {
  onConfigChange: (config: any) => void;
  initialConfig?: { questions: Question[] };
  disabled?: boolean;
}

const MathArit2Operand1PhaseConfig: React.FC<ConfigProps> = ({ onConfigChange, initialConfig }) => {
  const [questions, setQuestions] = useState<Question[]>(initialConfig?.questions.map((q) => ({ ...q, id: q.id || uuidv4() })) || []);

  useEffect(() => {
    // Ensure that the config JSON is properly updated and reflects each question independently
    onConfigChange({
      questions: questions.map((q) => ({
        id: q.id,
        operand1: q.randomizeAll || q.randomizeOperands ? null : q.operand1,
        operand2: q.randomizeAll || q.randomizeOperands ? null : q.operand2,
        operator: q.operator,
        randomizeOperands: q.randomizeOperands,
        randomizeAll: q.randomizeAll,
        digitCount: q.digitCount,
      })),
    });
  }, [questions, onConfigChange]);

  const addQuestion = () => {
    const newOrder = questions.length + 1;
    setQuestions([
      ...questions,
      {
        id: uuidv4(), // Assign a unique id to each new question
        order: newOrder,
        operand1: 0,
        operand2: 0,
        operator: '+',
        randomizeOperands: false,
        randomizeAll: false,
        digitCount: 1,
      },
    ]);
  };

  const updateQuestion = (updatedQuestion: Question) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === updatedQuestion.id ? { ...updatedQuestion } : q // Ensure updates target specific questions by id
      )
    );
  };

  return (
    <Box>
      {questions.map((question) => (
        <QuestionComponent
          key={question.id} // Use unique id as the key
          question={question}
          onUpdate={updateQuestion}
        />
      ))}

      <Button variant="contained" color="primary" onClick={addQuestion}>
        Lisää uusi tehtävä
      </Button>

      <Box mt={4}>
        <PreviewComponent questions={questions} />
      </Box>
    </Box>
  );
};

export default MathArit2Operand1PhaseConfig;
