import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { verifyMathAritRoundingPrecision, RoundingQuestion } from '../../../common/verifyMathAritRoundingPrecision'; // Assume this function exists for verification

interface MathAritRoundingStudentProps {
  config: {
    questions: RoundingQuestion[];
  };
  onAnswerChange: (index: number, answer: string) => void;
  disabled: boolean;
  feedback: { isCorrect: boolean; questionIndex: number }[] | null;
  initialAnswers: string[];
  assignmentStatus: 'New' | 'Active' | 'InReview';
  hasSubmitted: boolean;
}

const MathAritRoundingStudent: React.FC<MathAritRoundingStudentProps> = ({
  config,
  onAnswerChange,
  disabled,
  feedback,
  initialAnswers,
  assignmentStatus,
  hasSubmitted,
}) => {
  const [randomizedQuestions, setRandomizedQuestions] = useState<RoundingQuestion[]>([]);
  const [userAnswers, setUserAnswers] = useState<string[]>(initialAnswers);
  const [currentFeedback, setCurrentFeedback] = useState<{ isCorrect: boolean; questionIndex: number }[] | null>(null);

  useEffect(() => {
    setUserAnswers(initialAnswers); // Reset userAnswers whenever initialAnswers change
  }, [initialAnswers]);

  useEffect(() => {
    setRandomizedQuestions(config.questions);
  }, [config.questions]);

  useEffect(() => {
    // Recalculate feedback when assignment is in review or after submission
    if ((assignmentStatus === 'InReview' || hasSubmitted) && randomizedQuestions.length) {
      const evaluatedFeedback = randomizedQuestions.map((question, index) => {
        const result = verifyMathAritRoundingPrecision(question, userAnswers[index]);
        return {
          questionIndex: index,
          isCorrect: result.isCorrect,
          correctAnswer: result.correctAnswer,
        };
      });
      setCurrentFeedback(evaluatedFeedback);
    }
  }, [randomizedQuestions, userAnswers, assignmentStatus, hasSubmitted]);

  const handleInputChange = (value: string, index: number) => {
    const newAnswers = [...userAnswers];
    newAnswers[index] = value;
    setUserAnswers(newAnswers);
    onAnswerChange(index, value);
  };

  const shouldShowFeedback = assignmentStatus === 'InReview' || hasSubmitted;

  return (
    <Box mt={4}>
      <Typography variant="h5" mb={2}>Rounding Exercise</Typography>
      {randomizedQuestions.map((question, index) => {
        const feedbackItem = currentFeedback ? currentFeedback.find(fb => fb.questionIndex === index) : null;

        return (
          <Box key={question.id} mb={4} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6" component="div">
              Pyöristä {question.numberToRound} tarkkuudella {Math.pow(10, question.precision)} ({question.roundingMode})
            </Typography>

            <TextField
              value={userAnswers[index] || ''} // Ensure value is empty if reset
              onChange={(e) => handleInputChange(e.target.value, index)}
              disabled={disabled}
              sx={{ width: '100px' }}
            />

            {shouldShowFeedback && feedbackItem && (
              feedbackItem.isCorrect ? (
                <CheckCircleIcon sx={{ color: 'green' }} />
              ) : (
                <CancelIcon sx={{ color: 'red' }} />
              )
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default MathAritRoundingStudent;
