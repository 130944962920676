import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ExerciseCommonFields from '../../components/Exercise/ExerciseCommonFields';
import MathArit2Operand1PhaseConfig from '../../components/Exercise/MathArit2Operand1Phase/MathArit2Operand1PhaseConfig';
import { getExercise, updateExercise } from '../../services/exerciseService'; // Import getExercise and updateExercise services
import Breadcrumb from '../../components/Breadcrumb';
import MathAritRoundingConfig from '../../components/Exercise/MathAritRounding/MathAritRoundingConfig';
import FillInBlanksConfig from '../../components/Exercise/FillInTheBlanks/FillInBlanksConfig';
import PairingConfig from '../../components/Exercise/Pairing/PairingConfig';
import MaterialBasedConfig from '../../components/Exercise/MaterialBased/MaterialBasedConfig';
import SortToBoxesConfig from '../../components/Exercise/SortToBoxes/SortToBoxesConfig';

const ExerciseEditPage: React.FC = () => {
  const { lessonId, exerciseId } = useParams<{ lessonId: string; exerciseId: string }>();
  const { courseId } = useParams<{ courseId: string }>(); // Add courseId for navigation
  const navigate = useNavigate();
  const [exerciseType, setExerciseType] = useState<string | null>(null);
  const [exerciseConfig, setExerciseConfig] = useState<any>(null);
  
  // Common exercise fields state
  const [commonFields, setCommonFields] = useState({
    instructions: '',
    additionalInfo: '',
    order: 1,
    maxScore: 100,
  });

  useEffect(() => {
    const fetchExercise = async () => {
      if (lessonId && exerciseId) {
        try {
          const response = await getExercise(parseInt(lessonId, 10), parseInt(exerciseId, 10));
          const exerciseData = response.data;

          setExerciseType(exerciseData.type);
          setCommonFields({
            instructions: exerciseData.instructions,
            additionalInfo: exerciseData.additionalInfo,
            order: exerciseData.order,
            maxScore: exerciseData.maxScore,
          });
          setExerciseConfig(JSON.parse(exerciseData.config)); // Assuming config is a JSON string
        } catch (error) {
          console.error('Failed to load exercise:', error);
        }
      }
    };

    fetchExercise();
  }, [lessonId, exerciseId]);

  const handleCommonFieldChange = (field: string, value: any) => {
    setCommonFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleConfigChange = (config: any) => {
    setExerciseConfig(config);
  };

  const handleSaveExercise = async () => {
    if (!lessonId || !exerciseId || !exerciseType || !exerciseConfig) return;

    const exerciseData = {
      type: exerciseType,
      config: JSON.stringify(exerciseConfig), // Convert config to string
      instructions: commonFields.instructions,
      additionalInfo: commonFields.additionalInfo,
      order: commonFields.order,
      maxScore: commonFields.maxScore,
    };

    try {
      await updateExercise(parseInt(lessonId, 10), parseInt(exerciseId, 10), exerciseData);
      navigate(`/course/${courseId}/lesson/${lessonId}`); // Navigate back to the lesson page
    } catch (error) {
      console.error('Failed to save exercise:', error);
    }
  };

  const renderExerciseConfig = () => {
    switch (exerciseType) {
      case 'math_arit_2operand_1phase':
        return <MathArit2Operand1PhaseConfig initialConfig={exerciseConfig} onConfigChange={handleConfigChange} />;
      case 'math_arit_rounding_precision':
        return <MathAritRoundingConfig initialConfig={exerciseConfig} onConfigChange={handleConfigChange} />;
      case 'fill_in_the_blanks':
        return <FillInBlanksConfig initialConfig={exerciseConfig} onConfigChange={handleConfigChange} />;
      case 'pairing':
        return <PairingConfig initialConfig={exerciseConfig} onConfigChange={handleConfigChange} />;
      case 'material_based_ai_assisted':
        return <MaterialBasedConfig initialConfig={exerciseConfig} onConfigChange={handleConfigChange} />;
      case 'math_arit_2operand_2phase':
        return <MathArit2Operand1PhaseConfig initialConfig={exerciseConfig} onConfigChange={handleConfigChange} />;
        case 'sort_to_boxes':
          return <SortToBoxesConfig initialConfig={exerciseConfig} onConfigChange={handleConfigChange} />;
      default:
        return <Typography variant="body1">Harjoituksen tyyppiä ei tunnistettu</Typography>;
    }
  };

  return (
    <Container>
      {courseId && <Breadcrumb courseId={courseId} lessonId={lessonId} exerciseId={exerciseId} />}

      <Box mt={3}>
        <Typography variant="h4" gutterBottom>
          Muokkaa harjoitusta
        </Typography>

        {/* Exercise Common Fields */}
        {exerciseType && (
          <Box mb={4}>
            <ExerciseCommonFields
              instructions={commonFields.instructions}
              additionalInfo={commonFields.additionalInfo}
              order={commonFields.order}
              maxScore={commonFields.maxScore}
              onChange={handleCommonFieldChange}
            />
          </Box>
        )}

        {/* Exercise Configuration */}
        {exerciseType && (
          <Box mb={4}>
            {renderExerciseConfig()}
          </Box>
        )}

        {/* Save Button */}
        {exerciseType && (
          <Box mb={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveExercise}
            >
              Tallenna muutokset
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ExerciseEditPage;
