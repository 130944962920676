// src/components/Curriculum/CurriculumList.tsx
import React, { useEffect, useState } from 'react';
import { getCurriculums, deleteCurriculum } from '../../services/curriculumService';
import CurriculumItem from './CurriculumItem';
import { Curriculum } from '../../types/interfaces'; // Import the Curriculum type
import { Container, Grid } from '@mui/material';

const CurriculumList: React.FC = () => {
  const [curriculums, setCurriculums] = useState<Curriculum[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCurriculums();
      setCurriculums(response.data);
    };

    fetchData();
  }, []);

  const handleDelete = async (id: number) => {
    await deleteCurriculum(id);
    setCurriculums((prev) => prev.filter((curriculum) => curriculum.id !== id));
  };

  return (
    <Container>      
      <Grid container spacing={3}>
        {curriculums.map((curriculum) => (
          <Grid item xs={12} sm={6} md={4} key={curriculum.id}>
            <CurriculumItem curriculum={curriculum} onDelete={handleDelete} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CurriculumList;
