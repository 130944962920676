// src/components/Course/CourseList.tsx
import React, { useEffect, useState } from 'react';
import { getCourses, deleteCourse } from '../../services/courseService';
import CourseItem from './CourseItem';
import { Course } from '../../types/interfaces'; // Import the Course type
import { Container, Grid } from '@mui/material';

const CourseList: React.FC = () => {
  console.log('Render CourseList');
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCourses();
      setCourses(response.data);
    };

    fetchData();
  }, []);

  const handleDelete = async (id: number) => {
    await deleteCourse(id);
    setCourses((prev) => prev.filter((course) => course.id !== id));
  };

  return (
    <Container>      
      <Grid container spacing={3}>
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <CourseItem course={course} onDelete={handleDelete} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CourseList;
