export enum UserRole {
  Admin = 'A',
  Tutor = 'T',
  Student = 'S',
}

export enum MessageLevel {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}
