import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { getCourse } from '../services/courseService';
import { getLesson } from '../services/lessonService';
import { getExercise } from '../services/exerciseService'; // Add getExercise

interface BreadcrumbProps {
  courseId: string;
  lessonId?: string;
  exerciseId?: string; // Optional exerciseId for exercise-related breadcrumb
  additionalCrumb?: string;  // Optional prop for additional breadcrumb (e.g., assigning a student)
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ courseId, lessonId, exerciseId, additionalCrumb }) => {
  const [courseName, setCourseName] = useState<string | null>(null);
  const [lessonName, setLessonName] = useState<string | null>(null);
  const [exerciseName, setExerciseName] = useState<string | null>(null); // Add exerciseName state

  useEffect(() => {
    const fetchCourseName = async () => {
      if (courseId) {
        try {
          const course = await getCourse(parseInt(courseId, 10));
          setCourseName(course.data.name);
        } catch (error) {
          console.error('Failed to fetch course name:', error);
        }
      }
    };

    const fetchLessonName = async () => {      
      if (lessonId) {
        try {
          const lesson = await getLesson(parseInt(courseId, 10), parseInt(lessonId, 10));
          setLessonName(lesson.data.title);
        } catch (error) {
          console.error('Failed to fetch lesson name:', error);
        }
      }
    };

    const fetchExerciseName = async () => {
      if (exerciseId) {
        try {
          const exercise = await getExercise(parseInt(lessonId!, 10), parseInt(exerciseId, 10));
          setExerciseName(`Harjoitus ${exercise.data.order}`);
        } catch (error) {
          console.error('Failed to fetch exercise name:', error);
        }
      }
    };

    fetchCourseName();
    fetchLessonName();
    fetchExerciseName(); // Add fetchExerciseName call
  }, [courseId, lessonId, exerciseId]);

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
      <Link component={RouterLink} to="/course" underline="hover" color="inherit">
        Kurssit
      </Link>
      <Link component={RouterLink} to={`/course/${courseId}`} underline="hover" color="inherit">
        {courseName ? courseName : `Kurssi ${courseId}`}
      </Link>
      {lessonId && (
        <Link component={RouterLink} to={`/course/${courseId}/lesson/${lessonId}`} underline="hover" color="inherit">
          {lessonName ? lessonName : `Oppitunti ${lessonId}`}
        </Link>
      )}
      {exerciseId && (
        <Link component={RouterLink} to={`/course/${courseId}/lesson/${lessonId}/exercise/${exerciseId}`} underline="hover" color="inherit">
          {exerciseName ? exerciseName : `Harjoitus ${exerciseId}`}
        </Link>
      )}
      {additionalCrumb && (
        <Typography color="textPrimary">
          {additionalCrumb}
          {additionalCrumb.endsWith('*') ? '' : '*'} {/* Add asterisk if it's not already there */}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
