import api from './api';

export const generateQuestionsBasedOnMaterial = async (material: string, count: number) => {  
  try {   
    const request = {
      material,
      count,
    };

    const response = await api.post('/material/generatequestions', request, {
      headers: { 'Content-Type': 'application/json' },
    });

    return response.data;
  } catch (error) {
    console.error('Error generating questions:', error);
    throw error;
  }
};

// New function to verify and grade multiple answers
export const verifyAnswers = async (material: string, customPrompt: string, questionAnswers: { question: string, studentAnswer: string }[]) => {
  try {
    const request = {
      material,
      customPrompt,
      questionAnswers,
    };

    const response = await api.post('/material/verifyanswers', request, {
      headers: { 'Content-Type': 'application/json' },
    });

    return response.data; // Expecting an array of evaluations with grades and feedback
  } catch (error) {
    console.error('Error verifying answers:', error);
    throw error;
  }
};

// Function to simplify material into simpler language
export const simplifyMaterial = async (material: string) => {
  try {
    const request = {
      material,
    };

    const response = await api.post('/material/simplifymaterial', request, {
      headers: { 'Content-Type': 'application/json' },
    });

    return response.data; // Expecting simplified material as the response
  } catch (error) {
    console.error('Error simplifying material:', error);
    throw error;
  }
};

export const fixText = async (text: string) => {
  const request = { material: text }; // Modify request payload as needed
  const response = await api.post('/material/fix', request, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
};

// Function to translate text to Finnish
export const translateText = async (text: string, targetLanguage: string = 'fi') => {
  try {
    const request = {
      text,
      targetLanguage, // Specify the target language
    };

    const response = await api.post('/material/translate', request, {
      headers: { 'Content-Type': 'application/json' },
    });

    return response.data; // Expecting translated text as the response
  } catch (error) {
    console.error('Error translating text:', error);
    throw error;
  }
};


