import React, { useState, useEffect } from 'react';
import { Box, Typography, MenuItem, Select, FormControl, SelectChangeEvent } from '@mui/material';

interface VoiceOption {
  language: string;
  name: string;
  gender: string;
  code: string;
}

interface VoiceSelectorProps {
  onSelectVoice?: (voice: VoiceOption) => void;
  initialVoiceCode?: string; // Add initialVoiceCode prop
}

const voiceOptions: VoiceOption[] = [
  { language: 'English (US)', name: 'Jenny', gender: 'Female', code: 'en-US-JennyNeural' },
  { language: 'English (US)', name: 'Michael', gender: 'Male', code: 'en-US-MichaelNeural' },
  { language: 'English (UK)', name: 'Libby', gender: 'Female', code: 'en-GB-LibbyNeural' },
  { language: 'English (UK)', name: 'Ryan', gender: 'Male', code: 'en-GB-RyanNeural' },
  { language: 'Swedish', name: 'Hedvig', gender: 'Female', code: 'sv-SE-HedvigNeural' },
  { language: 'Swedish', name: 'Mattias', gender: 'Male', code: 'sv-SE-MattiasNeural' },
  { language: 'Spanish', name: 'Arabella', gender: 'Female', code: 'es-ES-ArabellaMultilingualNeural' },
  { language: 'Spanish', name: 'Sergio', gender: 'Male', code: 'es-ES-SergioNeural' },
  { language: 'Finnish', name: 'Noora', gender: 'Female', code: 'fi-FI-NooraNeural' },
  { language: 'Finnish', name: 'Harri', gender: 'Male', code: 'fi-FI-HarriNeural' },
  { language: 'Finnish', name: 'Selma', gender: 'Female', code: 'fi-FI-SelmaNeural' },
  { language: 'Italian', name: 'Isabella', gender: 'Female', code: 'it-IT-IsabellaNeuralMultilingualNeural' },
  { language: 'Italian', name: 'Diego', gender: 'Male', code: 'it-IT-DiegoNeural' },
  { language: 'French', name: 'Celeste', gender: 'Female', code: 'fr-FR-CelesteNeural' },
  { language: 'French', name: 'Henri', gender: 'Male', code: 'fr-FR-HenriNeural' },
];

const VoiceSelector: React.FC<VoiceSelectorProps> = ({ onSelectVoice, initialVoiceCode }) => {
  const [selectedVoice, setSelectedVoice] = useState<string>(initialVoiceCode || '');

  useEffect(() => {
    // Set the initial voice code if provided
    if (initialVoiceCode) {
      setSelectedVoice(initialVoiceCode);
    }
  }, [initialVoiceCode]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedVoiceCode = event.target.value;
    setSelectedVoice(selectedVoiceCode);

    const voice = voiceOptions.find((v) => v.code === selectedVoiceCode);
    if (voice && onSelectVoice) {
      onSelectVoice(voice);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Select Voice
      </Typography>
      <FormControl fullWidth>
        <Select value={selectedVoice} onChange={handleChange} displayEmpty>
          <MenuItem value="" disabled>
            Choose a voice
          </MenuItem>
          {voiceOptions.map((voice) => (
            <MenuItem key={voice.code} value={voice.code}>
              {`${voice.language} - ${voice.name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default VoiceSelector;
