import React, { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Checkbox, FormControlLabel, Typography, Divider } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PairingQuestion from './PairingQuestion';
import PairingPreview from './PairingPreview';

interface PairingQuestionConfig {
  id: string;
  termA: { text: string; language: string };
  termBOptions: string[];  // Array of correct answers as strings
  termBLanguage: string;  // Single language for all term B options
  hint: string;
}

interface PairingConfigProps {
  initialConfig?: any;
  onConfigChange: (config: any) => void;
}

const PairingConfig: React.FC<PairingConfigProps> = ({ initialConfig = {}, onConfigChange }) => {
  const [questions, setQuestions] = useState<PairingQuestionConfig[]>(initialConfig.questions || []);
  const [isVisual, setIsVisual] = useState(initialConfig.isVisual || false);

  const handleConfigChange = useCallback(() => {
    const config = {
      exerciseType: 'pairing',
      isVisual,
      questions,
    };
    onConfigChange(config);
  }, [isVisual, questions, onConfigChange]);

  useEffect(() => {
    handleConfigChange();
  }, [handleConfigChange]);

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        id: uuidv4(),
        termA: { text: '', language: 'en' },
        termBOptions: [''],
        termBLanguage: 'en',
        hint: '',
      },
    ]);
  };

  const updateQuestion = (index: number, updatedQuestion: PairingQuestionConfig) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = updatedQuestion;
    setQuestions(updatedQuestions);
  };

  const removeQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Paritehtävä - Asetukset</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={isVisual}
            onChange={() => setIsVisual(!isVisual)}
          />
        }
        label="Visuaalinen tila (vedä ja pudota)"
      />

      <Divider sx={{ my: 2 }} />

      {questions.map((question, index) => (
        <PairingQuestion
          key={question.id}
          index={index}
          question={question}
          onQuestionChange={updateQuestion}
          onRemoveQuestion={removeQuestion}
          isVisual={isVisual}
        />
      ))}

      <Box mt={2} mb={2}>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={addQuestion}
        >
          Lisää kysymys
        </Button>
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Esikatselu</Typography>
        <PairingPreview
          config={{
            questions,
            isVisual,
          }}
        />
      </Box>
    </Box>
  );
};

export default PairingConfig;
