// src/pages/Courses/CoursesPage.tsx
import React from 'react';
import CourseList from '../../components/Course/CourseList';
import { Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const CoursesPage: React.FC = () => (
  <div>
    <h1>Kurssit</h1>
    <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
      <Button variant="contained" color="primary" component={Link} to="/course/create">
        Luo uusi kurssi
      </Button>
    </Box>
    <CourseList />
  </div>
);

export default CoursesPage;
