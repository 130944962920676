// src/pages/Curriculums/CurriculumsPage.tsx
import React from 'react';
import CurriculumList from '../../components/Curriculum/CurriculumList';
import { Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const CurriculumsPage: React.FC = () => (
  <div>
    <h1>Opinto-ohjelmat</h1>
    <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
      <Button variant="contained" color="primary" component={Link} to="/curriculum/create">
        Luo uusi opetusohjelma
      </Button>
    </Box>
    <CurriculumList />
  </div>
);

export default CurriculumsPage;
