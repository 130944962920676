import React, { useState, useRef } from 'react';
import { Box, Button, Typography, IconButton, TextField } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { extractText } from '../../../services/visionService';
import { generateQuestionsBasedOnMaterial, verifyAnswers } from '../../../services/aiExerciseService';  
import VoiceSelector from '../../common/VoiceSelector';
import SpeechButton from '../../common/SpeechButton';

interface MaterialBasedConfigProps {
  onTextExtracted?: (text: string) => void;
  initialConfig?: any;
  onConfigChange: (config: any) => void;
}

const MaterialBasedConfig: React.FC<MaterialBasedConfigProps> = ({ onTextExtracted, onConfigChange, initialConfig = {} }) => {
  const [editorState, setEditorState] = useState<EditorState>(
    initialConfig.capturedText
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(initialConfig.capturedText)))
      : EditorState.createEmpty(),
  );

  const [questions, setQuestions] = useState<string[]>(initialConfig.questions || []);
  const [customPrompt, setCustomPrompt] = useState<string>(initialConfig.customPrompt || '');
  const [isCapturing, setIsCapturing] = useState<boolean>(false);
  const [questionCount, setQuestionCount] = useState<number>(1);
  const [answers, setAnswers] = useState<string[]>([]);
  const [feedbacks, setFeedbacks] = useState<{ grade: number; feedback: string }[]>([]);
  const [selectedVoiceCode, setSelectedVoiceCode] = useState<string>(initialConfig.voiceCode || 'en-US-JennyNeural');
  const [isFrontCamera, setIsFrontCamera] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const getPlainText = () => editorState.getCurrentContent().getPlainText();

  const updateCapturedText = () => {
    const rawContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    onConfigChange({
      ...initialConfig,
      capturedText: rawContent,
      voiceCode: selectedVoiceCode,
      customPrompt,
    });
  };

  const handleAnswerChange = (index: number, value: string) => {
    const updatedAnswers = [...answers];
    updatedAnswers[index] = value;
    setAnswers(updatedAnswers);
  };

  const startCamera = async () => {
    setIsCapturing(true);
    const constraints = {
      video: {
        facingMode: isFrontCamera ? 'user' : 'environment',
      },
    };
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
      await videoRef.current.play();
    }
  };
  

  const capturePhoto = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

        canvasRef.current.toBlob(async (blob) => {
          if (blob) {
            try {
              const extractedText = await extractText(blob);
              const updatedEditorState = EditorState.createWithContent(
                convertFromRaw({
                  blocks: [
                    {
                      text: getPlainText() + '\n' + extractedText.data,
                      type: 'unstyled',
                      depth: 0,
                      entityRanges: [],
                      inlineStyleRanges: [],
                      key: '',
                    },
                  ],
                  entityMap: {},
                }),
              );
              setEditorState(updatedEditorState);
              updateCapturedText();
              if (onTextExtracted) onTextExtracted(extractedText.data);
            } catch (error) {
              console.error('Error extracting text:', error);
            }
          }
        }, 'image/png');
      }
    }
    setIsCapturing(false);
    stopCamera();
  };

  const stopCamera = () => {
    const stream = videoRef.current?.srcObject as MediaStream;
    stream?.getTracks().forEach(track => track.stop());
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  const addQuestion = () => {
    setQuestions([...questions, '']);
    setAnswers([...answers, '']);
  };

  const removeQuestion = (index: number) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    const updatedAnswers = answers.filter((_, i) => i !== index);
    const updatedFeedbacks = feedbacks.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
    setAnswers(updatedAnswers);
    setFeedbacks(updatedFeedbacks);
    onConfigChange({
      ...initialConfig,
      questions: updatedQuestions,
      capturedText: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      customPrompt,
      voiceCode: selectedVoiceCode,
    });
  };

  const handleQuestionChange = (index: number, value: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
    onConfigChange({
      ...initialConfig,
      questions: updatedQuestions,
      capturedText: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      customPrompt,
      voiceCode: selectedVoiceCode,
    });
  };

  const handleCustomPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPrompt = e.target.value;
    setCustomPrompt(newPrompt);
    onConfigChange({
      ...initialConfig,
      customPrompt: newPrompt,
      capturedText: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      voiceCode: selectedVoiceCode,
      questions,
    });
  };

  const generateAIQuestions = async () => {
    try {
      const generatedQuestions = await generateQuestionsBasedOnMaterial(getPlainText(), questionCount);
      const newQuestions = [...questions, ...generatedQuestions.data];
      setQuestions(newQuestions);
      setAnswers([...answers, ...new Array(generatedQuestions.data.length).fill('')]);
      onConfigChange({
        ...initialConfig,
        questions: newQuestions,
        capturedText: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        customPrompt,
        voiceCode: selectedVoiceCode,
      });
    } catch (error) {
      console.error('Error generating questions:', error);
    }
  };

  const clearTextAndQuestions = () => {
    setEditorState(EditorState.createEmpty());
    setQuestions([]);
    setAnswers([]);
    setCustomPrompt('');
    setFeedbacks([]);
    onConfigChange({ capturedText: '', questions: [], customPrompt: '', voiceCode: selectedVoiceCode });
  };

  const evaluateAnswer = async (index: number) => {
    try {
      const questionAnswerPair = [{ question: questions[index], studentAnswer: answers[index] }];
      const response = await verifyAnswers(JSON.stringify(convertToRaw(editorState.getCurrentContent())), customPrompt, questionAnswerPair);
      const newFeedbacks = [...feedbacks];
      newFeedbacks[index] = response.data[0];
      setFeedbacks(newFeedbacks);
    } catch (error) {
      console.error('Error verifying answer:', error);
    }
  };

  const handleVoiceSelect = (voice: { code: string }) => {
    setSelectedVoiceCode(voice.code);
    onConfigChange({
      ...initialConfig,
      voiceCode: voice.code,
      customPrompt,
      capturedText: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      questions,
    });
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Materiaalipohjainen harjoitus - Tekstin poiminta
      </Typography>

      <VoiceSelector onSelectVoice={handleVoiceSelect} initialVoiceCode={selectedVoiceCode} />

      <Button
        variant="contained"
        startIcon={<CameraAltIcon />}
        onClick={isCapturing ? capturePhoto : startCamera}
        sx={{ mb: 2 }}
      >
        {isCapturing ? 'Ota kuva' : 'Käynnistä kamera'}
      </Button>      
      <Button
        variant="outlined"
        onClick={() => {
          setIsFrontCamera((prev) => !prev);
          stopCamera(); // Stop current stream
          startCamera(); // Restart with new facing mode
        }}
        sx={{ mb: 2, ml: 2 }}
      >
        Vaihda kamera
      </Button>
      &nbsp;
      <Button
        variant="contained"
        startIcon={<ClearAllIcon />}
        onClick={clearTextAndQuestions}
        sx={{ mb: 2 }}
      >
        Tyhjennä
      </Button>

      <Box display={isCapturing ? 'block' : 'none'}>
        <video ref={videoRef} style={{ width: '100%', maxHeight: '300px' }} />
        <canvas ref={canvasRef} style={{ display: 'none' }} />
      </Box>

      {/* Rich Text Editor for Captured Text */}
      <Box border={1} borderColor="lightgray" borderRadius={2} p={2} mt={2}>
        <Typography variant="h6">Materiaaliteksti</Typography>
        <Editor
          editorState={editorState}
          onEditorStateChange={(state) => {
            setEditorState(state);
            updateCapturedText();
          }}
        />
        <SpeechButton id="material-speech" text={getPlainText()} voiceCode={selectedVoiceCode} />
      </Box>

      {/* Custom Prompt Section */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6">Mukautettu kehotus</Typography>
        <TextField
          label="Kirjoita mukautettu kehotus"
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          value={customPrompt}
          onChange={handleCustomPromptChange}
          sx={{ mt: 1 }}
        />
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h6">Kysymykset ja vastaukset</Typography>
        {questions.map((question, index) => (
          <Box key={index} sx={{ mt: 2 }}>
            <Box display="flex" alignItems="center">
              <TextField
                label={`Kysymys ${index + 1}`}
                variant="outlined"
                fullWidth
                value={question}
                onChange={(e) => handleQuestionChange(index, e.target.value)}
              />
              <IconButton onClick={() => removeQuestion(index)} sx={{ ml: 1 }}>
                <DeleteIcon />
              </IconButton>
            </Box>

            <TextField
              label="Vastaus"
              variant="outlined"
              fullWidth
              value={answers[index]}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              sx={{ mt: 1 }}
            />
            <Button variant="contained" onClick={() => evaluateAnswer(index)} sx={{ mt: 1 }}>
              Tarkista vastaus
            </Button>
            {feedbacks[index] && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="body1">Arvosana: {feedbacks[index].grade}</Typography>
                <Typography variant="body2">Palaute: {feedbacks[index].feedback}</Typography>
              </Box>
            )}
            <SpeechButton id={`question-speech-${index}`} text={question} voiceCode={selectedVoiceCode} />
          </Box>          
        ))}
        
        <Button
          variant="outlined"
          startIcon={<AddCircleIcon />}
          onClick={addQuestion}
          sx={{ mt: 2 }}
        >
          Lisää kysymys
        </Button>

        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          <TextField
            label="Kysymysten määrä"
            type="number"
            variant="outlined"
            value={questionCount}
            onChange={(e) => setQuestionCount(parseInt(e.target.value, 10))}
            sx={{ width: '120px', mr: 2 }}
          />
          <Button variant="contained" onClick={generateAIQuestions}>
            Generoi kysymykset tekoälyllä
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MaterialBasedConfig;
