// src/pages/Curriculums/CurriculumCreatePage.tsx
import React from 'react';
import CurriculumForm from '../../components/Curriculum/CurriculumForm';
import { createCurriculum } from '../../services/curriculumService';
import { useNavigate } from 'react-router-dom';

const CurriculumCreatePage: React.FC = () => {
  const navigate = useNavigate();

  const handleCreate = async (data: { name: string; description: string; startDate: string; endDate: string; }) => {
    await createCurriculum(data);
    navigate('/curriculum');
  };

  return (
    <div>
      <h1>Luo opinto-ohjelma</h1>
      <CurriculumForm onSubmit={handleCreate} />
    </div>
  );
};

export default CurriculumCreatePage;
