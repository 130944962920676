import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Modal, Paper } from '@mui/material';
import {
  getAssignmentById,
  submitAssignmentAnswers,
  resetAssignment,
} from '../../../services/assignmentService';
import StudentAssignment from './../../../components/Assignment/StudentAssignment';
import { useAuth } from '../../../components/AuthContext';
import { useScore } from '../../../components/ScoreContext';

const StudentAssignmentPage: React.FC = () => {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const [assignmentData, setAssignmentData] = useState<any>(null);
  const { user } = useAuth();
  const [answers, setAnswers] = useState<string[]>([]);
  const [result, setResult] = useState<any>(null);
  const [isResetModalOpen, setResetModalOpen] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { refreshScore } = useScore();

  useEffect(() => {
    const fetchAssignment = async () => {
      try {
        if (!assignmentId) return;
  
        const response = await getAssignmentById(parseInt(assignmentId, 10));
  
        const config = JSON.parse(response.data.exercise.config || '{}');
        const initialAnswers = response.data.studentAnswers
          ? JSON.parse(response.data.studentAnswers)
          : Array(config.questions?.length || 0).fill('');
  
        setAssignmentData(response.data);
        setAnswers(initialAnswers); // Set initial answers
      } catch (error) {
        console.error('Failed to fetch assignment:', error);
      }
    };
  
    fetchAssignment();
  }, [assignmentId]);
  

  const handleAnswerChange = (index: number, answer: string) => {
    // Use the functional form of setState to ensure you're updating based on the latest state
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[index] = answer;
      return newAnswers;
    });
  };

  const handleSubmit = async () => {
    if (!assignmentData || !user) return;

    try {
      const response = await submitAssignmentAnswers({
        assignmentId: assignmentData.id,
        studentEmail: user.email,
        type: assignmentData.exercise.type,
        answers,
      });

      setResult(response.data); // Set the result from the backend to display feedback
      setHasSubmitted(true); // Set submission state to true to hide the button
      refreshScore();
    } catch (error) {
      console.error('Vastausten lähettäminen epäonnistui:', error);
    }
  };

  const handleReset = async () => {
    try {
      if (!assignmentId) {
        return;
      }
      await resetAssignment(assignmentId);
      setResetModalOpen(false);

      // Clear the answers in the UI
      const resetAnswers = Array(
        JSON.parse(assignmentData.exercise.config).questions.length
      ).fill('');
      setAnswers(resetAnswers); // Clear answers in the UI
      setResult(null);
      setHasSubmitted(false); // Reset the submission state

      // Update assignmentData's studentAnswers to match reset state
      setAssignmentData((prevData: any) => ({
        ...prevData,
        studentAnswers: JSON.stringify(resetAnswers),
      }));

      refreshScore();
    } catch (error) {
      console.error('Failed to reset assignment:', error);
    }
  };

  if (!assignmentData) {
    return <div>Loading...</div>;
  }

  const isAssignmentInReview = assignmentData.status === 'InReview';

  return (
    <Box mt={4} mb={2}>
      <StudentAssignment
        type={assignmentData.exercise.type}
        config={JSON.parse(assignmentData.exercise.config)}
        instructions={assignmentData.exercise.instructions}
        order={assignmentData.exercise.order}
        additionalInfo={assignmentData.exercise.additionalInfo}
        onAnswerChange={handleAnswerChange}
        onSubmitAnswers={handleSubmit}
        disabled={isAssignmentInReview || hasSubmitted}
        feedback={result ? result.feedback : null}
        initialAnswers={answers}
        assignmentStatus={assignmentData.status}
        hasSubmitted={hasSubmitted}
      />

      <Box display="flex" alignItems="center" gap={2} mt={2}>
        {!isAssignmentInReview && !hasSubmitted && (
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Lähetä vastaukset
          </Button>
        )}

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setResetModalOpen(true)}
        >
          Nollaa tehtävä
        </Button>
      </Box>

      {result && (
        <Box mt={2}>
          <Typography variant="h6">Tulokset</Typography>
          <Typography>
            Pisteet: {result.score} / {result.totalQuestions}
          </Typography>
          {result.feedback.map(
            (
              item: {
                isCorrect: boolean;
                questionIndex: number;
                correctAnswer: any;
              },
              index: React.Key | null | undefined
            ) => (
              <Typography
                key={index}
                color={item.isCorrect ? 'green' : 'red'}
              >
                Kysymys {item.questionIndex + 1}:{' '}
                {item.isCorrect
                  ? 'Oikein'
                  : `Väärin (Oikea vastaus: ${item.correctAnswer})`}
              </Typography>
            )
          )}
        </Box>
      )}

      {/* Reset Confirmation Modal */}
      <Modal
        open={isResetModalOpen}
        onClose={() => setResetModalOpen(false)}
      >
        <Paper sx={{ padding: 4, width: 300, margin: '20vh auto' }}>
          <Typography variant="h6" gutterBottom>
            Haluatko varmasti nollata tehtävän?
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleReset}
            sx={{ mr: 1 }}
          >
            Kyllä
          </Button>
          <Button
            variant="outlined"
            onClick={() => setResetModalOpen(false)}
          >
            Ei
          </Button>
        </Paper>
      </Modal>
    </Box>
  );
};

export default StudentAssignmentPage;
