// src/pages/Courses/CourseEditPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CourseForm from '../../components/Course/CourseForm';
import { getCourse, updateCourse } from '../../services/courseService';
import { Container, Typography, CircularProgress } from '@mui/material';

const CourseEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState<{ name: string; description: string; startDate: string; endDate: string; subject: string; subjectSpecification: string } | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await getCourse(parseInt(id, 10));
        setInitialData(response.data);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  const handleUpdate = async (data: { name: string; description: string; startDate: string; endDate: string; subject: string; subjectSpecification: string }) => {
    if (id) {
      await updateCourse(parseInt(id, 10), data);
      navigate('/course');
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Muokkaa kurssia
      </Typography>
      {initialData ? (
        <CourseForm initialData={initialData} onSubmit={handleUpdate} />
      ) : (
        <Typography variant="h6">kurssia ei löytynyt</Typography>
      )}
    </Container>
  );
};

export default CourseEditPage;
