export interface RoundingQuestion {
  id: string;
  numberToRound: number;
  precision: number; // Number of decimal places or rounding factor (e.g., 10 for tens, 100 for hundreds)
  roundingMode: 'up' | 'down' | 'nearest';
}

export interface AnswerVerificationResult {
  isCorrect: boolean;
  correctAnswer: number;
}

export const verifyMathAritRoundingPrecision = (question: RoundingQuestion, studentAnswer: string): AnswerVerificationResult => {
  const { numberToRound, precision, roundingMode } = question;

  let correctAnswer: number;

  // Determine the rounding factor based on the precision setting
  const factor = Math.pow(10, precision);

  try {
    switch (roundingMode) {
      case 'up':
        correctAnswer = Math.ceil(numberToRound / factor) * factor;
        break;
      case 'down':
        correctAnswer = Math.floor(numberToRound / factor) * factor;
        break;
      case 'nearest':
      default:
        correctAnswer = Math.round(numberToRound / factor) * factor;
        break;
    }
  } catch (error) {
    console.error('Error during rounding calculation:', error);
    correctAnswer = NaN;
  }

  return {
    isCorrect: parseFloat(studentAnswer) === correctAnswer,
    correctAnswer,
  };
};
