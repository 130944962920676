import axios, { AxiosResponse } from 'axios';
import { ApiResponse, ApiMessage } from '../types/interfaces';
import { showToast } from './toastService';

let setLoading: (loading: boolean) => void;

export const initializeLoadingState = (setLoadingFunction: (loading: boolean) => void) => {
  setLoading = setLoadingFunction;
};

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
});

// Function to check if token is expired
const isTokenExpired = (token: string) => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload.exp * 1000 < Date.now();
};

api.interceptors.request.use(
  async (config) => {
    setLoading(true);
    let token = localStorage.getItem('token') ?? '';
    const refreshToken = localStorage.getItem('refreshToken');

    if (token && isTokenExpired(token) && refreshToken) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, { refreshToken });
        token = response.data.accessToken;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', response.data.refreshToken); // Update refresh token if a new one is issued
      } catch (error) {
        console.error('Failed to refresh token', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        setLoading(false);
        showToast({ level: 'error', text: 'Failed to refresh token' });
        return Promise.reject(error);
      }
    }

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    setLoading(false);
    showToast({ level: 'error', text: 'Request error' });
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse<ApiResponse<any>>) => {
    setLoading(false);
    // Check if response is a blob (binary) type based on content type headers
    const contentType = response.headers['content-type'];
    if (contentType && contentType.includes('audio/wav')) {
      // Handle binary blob response (e.g., audio file)
      return response; // Return response directly to handle blob
    } else if (response.data && response.data.data !== undefined && Array.isArray(response.data.messages)) {
      // Handle standard API response with messages
      const apiResponse: ApiResponse<any> = response.data;
      apiResponse.messages.forEach((message: ApiMessage) => showToast(message));
      return response; // Return the original AxiosResponse
    } else if (response.status === 204) {
      // Handle 204 No Content response
      return response; // Return the original AxiosResponse
    } else {
      console.error('Response does not follow the expected schema', response);
      showToast({ level: 'error', text: 'Unexpected response schema' });
      return Promise.reject(new Error('Response does not follow the expected schema'));
    }
  },
  async (error) => {
    setLoading(false);
    console.error('API response error:', error);
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem('refreshToken');
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh`, { refreshToken });
          const newAccessToken = response.data.accessToken;
          localStorage.setItem('token', newAccessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken); // Update refresh token if a new one is issued
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          console.error('Failed to refresh token', refreshError);
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('user');
          showToast({ level: 'error', text: 'Failed to refresh token' });
          return Promise.reject(refreshError);
        }
      } else if (error.response.status >= 500) {
        console.error('Server error:', error.response);
        showToast({ level: 'error', text: 'An unexpected error occurred. Please try again later.' });
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
      showToast({ level: 'error', text: 'No response from server. Please check your network connection.' });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error in request setup:', error.message);
      showToast({ level: 'error', text: 'An unexpected error occurred. Please try again later.' });
    }
    return Promise.reject(error);
  }
);

export default api;
