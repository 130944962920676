import React from 'react';
import { Box, TextField, MenuItem, Typography, Button } from '@mui/material';

interface Question {
  id: string; // Unique identifier for the question
  order: number;
  operand1: number;
  operand2: number;
  operator: '+' | '-' | '*' | '/';
  randomizeOperands: boolean;
  randomizeAll: boolean;
  digitCount: number;
}

interface MathArit2Operand1PhaseQuestionProps {
  question: Question;
  onUpdate: (updatedQuestion: Question) => void;
}

const MathArit2Operand1PhaseQuestion: React.FC<MathArit2Operand1PhaseQuestionProps> = ({
  question,
  onUpdate,
}) => {
  // Handle input change for fields within the question
  const handleInputChange = (field: keyof Question, value: any) => {
    // Update the question object and send it back to the parent component
    onUpdate({
      ...question, // Spread current question state
      [field]: value, // Update the specific field that changed
    });
  };

  const handleRandomize = () => {
    const newOperand1 = Math.floor(Math.random() * Math.pow(10, question.digitCount));
    const newOperand2 = Math.floor(Math.random() * Math.pow(10, question.digitCount));

    // Update operands with new random values
    onUpdate({
      ...question,
      operand1: newOperand1,
      operand2: newOperand2,
    });
  };

  return (
    <Box mb={4}>
      <Typography variant="h6">Tehtävä {question.order}</Typography>
      <Box display="flex" alignItems="center" gap={2}>
        <TextField
          label="Operand 1"
          type="number"
          name="operand1"
          value={question.operand1}
          onChange={(e) => handleInputChange('operand1', parseInt(e.target.value, 10))}
          disabled={question.randomizeAll || question.randomizeOperands}
        />
        <TextField
          select
          label="Operator"
          name="operator"
          value={question.operator}
          onChange={(e) => handleInputChange('operator', e.target.value)}
        >
          <MenuItem value="+">+</MenuItem>
          <MenuItem value="-">-</MenuItem>
          <MenuItem value="*">*</MenuItem>
          <MenuItem value="/">/</MenuItem>
        </TextField>
        <TextField
          label="Operand 2"
          type="number"
          name="operand2"
          value={question.operand2}
          onChange={(e) => handleInputChange('operand2', parseInt(e.target.value, 10))}
          disabled={question.randomizeAll || question.randomizeOperands}
        />
        <Button onClick={handleRandomize} variant="outlined">
          Randomisoi
        </Button>
        {(question.randomizeOperands || question.randomizeAll) && (
          <TextField
            label="Numeroiden määrä"
            type="number"
            name="digitCount"
            value={question.digitCount}
            onChange={(e) => handleInputChange('digitCount', parseInt(e.target.value, 10))}
            inputProps={{ min: 1 }} // Ensures that at least 1 digit is selected
          />
        )}
      </Box>
    </Box>
  );
};

export default MathArit2Operand1PhaseQuestion;
