import React, { useState } from 'react';
import { TextField, Button, Container, Box, FormControlLabel, Checkbox } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

const LessonForm: React.FC<{
  initialData?: {
    title: string;
    description: string;
    order: number;
    isActive: boolean;
  };
  onSubmit: (data: {
    title: string;
    description: string;
    order: number;
    isActive: boolean;
  }) => void;
}> = ({ initialData, onSubmit }) => {
  const [formData, setFormData] = useState(initialData || {
    title: '',
    description: '',
    order: 1,
    isActive: true,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: name === 'order' ? parseInt(value, 10) : value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, isActive: e.target.checked });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Container maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Oppitunnin nimi"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          label="Kuvaus"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          multiline
          rows={4}
          fullWidth
        />
        <TextField
          label="Järjestys"
          type="number"
          name="order"
          value={formData.order}
          onChange={handleChange}
          required
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.isActive}
              onChange={handleCheckboxChange}
              name="isActive"
              color="primary"
            />
          }
          label="Aktiivinen"
        />
        <Button variant="contained" color="primary" type="submit" startIcon={<SaveIcon />}>
          Tallenna
        </Button>
      </Box>
    </Container>
  );
};

export default LessonForm;
