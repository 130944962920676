import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { verifyMathArit2Operand1Phase, Question } from '../../../common/verifyMathArit2Operand1Phase';
import FinnishNumberInput from '../../inputs/FinnishNumberInput'; // Import the FinnishNumberInput component

interface MathArit2Operand1PhasePreviewProps {
  questions: Question[];
}

const generateRandomNumber = (digitCount: number) => {
  const min = Math.pow(10, digitCount - 1);
  const max = Math.pow(10, digitCount) - 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const MathArit2Operand1PhasePreview: React.FC<MathArit2Operand1PhasePreviewProps> = ({ questions }) => {
  const [userAnswers, setUserAnswers] = useState<string[]>(Array(questions.length).fill(''));
  const [feedback, setFeedback] = useState<(boolean | null)[]>(Array(questions.length).fill(null));
  const [randomizedQuestions, setRandomizedQuestions] = useState<Question[]>([]);

  useEffect(() => {
    const randomized = questions.map((question) => {
      const operand1 = question.randomizeAll || question.randomizeOperands 
        ? generateRandomNumber(question.digitCount)
        : question.operand1;

      const operand2 = question.randomizeAll 
        ? generateRandomNumber(question.digitCount)
        : question.randomizeOperands 
        ? generateRandomNumber(question.digitCount)
        : question.operand2;

      return {
        ...question,
        operand1,
        operand2,
      };
    });
    setRandomizedQuestions(randomized);
  }, [questions]);

  const handleInputChange = (value: string, index: number) => {
    const newAnswers = [...userAnswers];
    newAnswers[index] = value;
    setUserAnswers(newAnswers);
  };

  const checkAnswer = (index: number) => {
    const question = randomizedQuestions[index];
    const result = verifyMathArit2Operand1Phase(question, userAnswers[index]);

    const newFeedback = [...feedback];
    newFeedback[index] = result.isCorrect;
    setFeedback(newFeedback);
  };

  return (
    <Box mt={4}>
      <Typography variant="h5" mb={2}>Esikatselu</Typography>
      {randomizedQuestions.map((question, index) => (
        <Box key={index} mb={4} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h6" component="div">
            {question.operand1} {question.operator} {question.operand2} =
          </Typography>

          <FinnishNumberInput
            value={userAnswers[index]}
            onChange={(value) => handleInputChange(value, index)}
            disabled={false} // or set this dynamically if needed
            sx={{
              width: '100px', // Adjust the width if necessary
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: feedback[index] === true ? '2px solid green' : feedback[index] === false ? '2px solid red' : '',
                },
              },
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => checkAnswer(index)}
          >
            Tarkista
          </Button>

          {feedback[index] !== null && (
            feedback[index] === true ? (
              <CheckCircleIcon sx={{ color: 'green' }} />
            ) : (
              <CancelIcon sx={{ color: 'red' }} />
            )
          )}
        </Box>
      ))}
    </Box>
  );
};

export default MathArit2Operand1PhasePreview;
