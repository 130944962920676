// src/services/curriculumService.ts
import api from './api';

export const getCurriculums = async () => {
  const response = await api.get('/curriculum');
  return response.data;
};

export const getCurriculum = async (id: number) => {
  const response = await api.get(`/curriculum/${id}`);
  return response.data;
};

export const createCurriculum = async (curriculum: { name: string; description: string; startDate: string; endDate: string; }) => {
  const response = await api.post('/curriculum', curriculum);
  return response.data;
};

export const updateCurriculum = async (id: number, curriculum: { name: string; description: string; startDate: string; endDate: string; }) => {
  const response = await api.put(`/curriculum/${id}`, curriculum);
  return response.data;
};

export const deleteCurriculum = async (id: number) => {
  const response = await api.delete(`/curriculum/${id}`);
  return response.data;
};
