import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import CourseDetails from '../../components/Course/CourseDetails';
import Breadcrumb from '../../components/Breadcrumb';
import { getCourse } from '../../services/courseService';
import { Course } from '../../types/interfaces';
import { Container, CircularProgress, Typography, Button, Box } from '@mui/material';
import LessonList from '../../components/Lesson/LessonList';  // Import the LessonList component
import AddIcon from '@mui/icons-material/Add';

const CourseViewPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [course, setCourse] = useState<Course | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await getCourse(parseInt(id, 10));
        setCourse(response.data);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!course) {
    return (
      <Container>
        <Typography variant="h5" component="div">
          Kurssia ei löytynyt
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      {/* Breadcrumb Navigation */}
      {id && <Breadcrumb courseId={id} />} 

      {/* Course Details */}
      <Box mb={3}>
        <CourseDetails course={course} />
      </Box>

      {/* Lesson Creation Button */}
      <Box mb={4}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/course/${id}/lesson/create`}
          sx={{ textAlign: 'left' }}
          startIcon={<AddIcon />}>
          Lisää uusi oppitunti
        </Button>
      </Box>

      {/* Lesson List */}      
      <Box mb={6}>
        {id && <LessonList courseId={id} />} {/* Ensure id is not undefined */}
      </Box>
    </Container>
  );
};

export default CourseViewPage;
