import api from './api';
import { Assignment } from '../types/interfaces';

export const createAssignment = async (assignment: { 
  studentId: number; 
  exerciseId: number | null; 
  status: string; 
  dueDate?: Date | null; 
  maxAttemptsAllowed?: number | null; 
  isGradedManually?: boolean; 
  supplementalMaterial?: string | null;
  assignerEmail?: string;
}) => {
  const response = await api.post('/tutor/assignment', assignment);
  return response.data;
};

export const getAssignmentsByStudent = async (studentId: number) => {
  const response = await api.get(`/tutor/assignment/student/${studentId}`);
  return response.data;
};

export const getLatestAssignmentsByStudent = async (email: string) => {
  const response = await api.get(`/student/assignment/${email}/all`, {
    params: {
      limit: 3,
      order: { field: 'dueDate', direction: 'DESC' },
    },
  });
  return response.data;
};

export const getAssignmentById = async (assignmentId: number) => {
  const response = await api.get(`/student/assignment/${assignmentId}`);
  return response.data;
};

export const updateAssignment = async (studentId: number, assignment: Assignment) => {
  const response = await api.put(`/tutor/assignment/${studentId}`, assignment);
  return response.data;
};

export const deleteAssignment = async (studentId: number, exerciseId: number) => {
  const response = await api.delete(`/tutor/assignment/student/${studentId}/exercise/${exerciseId}`);
  return response.data;
};

export const approveAssignment = async (id: number, approval: { tutorFeedback?: string, status: string }) => {
  const response = await api.put(`/tutor/assignment/${id}/approve`, approval);
  return response.data;
};

export const getAssignmentsByExercise = async (exerciseId: number) => {
  const response = await api.get(`/tutor/assignment/exercise/${exerciseId}`);
  return response.data;
};

// Implementation of submitAssignmentAnswers
export const submitAssignmentAnswers = async (submissionData: { 
  assignmentId: number; 
  studentEmail: string; 
  type: string; 
  answers: string[] 
}) => {
  const response = await api.post(`/student/assignment/${submissionData.assignmentId}/submit`, submissionData);

  return response.data;
};

export const resetAssignment = async (assignmentId: string) => {
  const response = await api.post(`/student/assignment/${assignmentId}/reset`);
  return response.data;
};

// Fetch user score by email
export const getUserScoreByEmail = async (email: string) => {
  const response = await api.get(`/student/assignment/${email}/score`);
  return response.data;
};
