import React, { useState } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { synthesizeSpeech, playSpeech } from '../../services/speechService';

interface SpeechButtonProps {
  id: string;
  text: string;
  voiceCode?: string; // Optional voice code prop, defaults to 'en-US-JennyNeural'
}

const SpeechButton: React.FC<SpeechButtonProps> = ({ id, text, voiceCode = 'en-US-JennyNeural' }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    const uuid = id;

    try {
      // Use the specified voice code instead of language code
      console.log('Synthesizing speech with voice code:', voiceCode);
      const audioArrayBuffer = await synthesizeSpeech(uuid, text, voiceCode);
      console.log('Speech synthesized successfully:', audioArrayBuffer);

      await playSpeech(audioArrayBuffer, () => {
        console.log('Speech synthesized and played successfully');
      }, uuid);
    } catch (error) {
      console.error('Error synthesizing, playing, or uploading speech:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <IconButton onClick={handleClick} disabled={loading} aria-label="Play Speech">
      {loading ? <CircularProgress size={24} /> : <VolumeUpIcon />}
    </IconButton>
  );
};

export default SpeechButton;
