import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, CircularProgress, Button } from '@mui/material';
import { useParams, Link as RouterLink } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb';
import { getExercise } from '../../services/exerciseService';
import ExerciseDetails from './../../components/Exercise/ExerciseDetails';
//import ExerciseConfigView from '../../components/Exercise/ExerciseConfigView'; // Hypothetical component to display config
import EditIcon from '@mui/icons-material/Edit';

const ExerciseViewPage: React.FC = () => {
  const { courseId, lessonId, exerciseId } = useParams<{ courseId: string; lessonId: string; exerciseId: string }>();
  const [exercise, setExercise] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (courseId && lessonId && exerciseId) {
        try {
          const response = await getExercise(parseInt(lessonId, 10), parseInt(exerciseId, 10));
          setExercise(response.data);
        } catch (error) {
          console.error('Failed to load exercise:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [courseId, lessonId, exerciseId]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!exercise) {
    return (
      <Container>
        <Typography variant="h5" component="div">
          Harjoitusta ei löytynyt
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      {/* Breadcrumb Navigation */}
      {courseId && lessonId && exerciseId && <Breadcrumb courseId={courseId} lessonId={lessonId} exerciseId={exerciseId} />}

      {/* Exercise Details */}
      <Box mb={3}>
        <ExerciseDetails exercise={exercise} />
      </Box>

      {/* Edit Exercise Button */}
      <Box mb={4}>
        {lessonId && courseId && exerciseId && (
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`/course/${courseId}/lesson/${lessonId}/exercise/${exerciseId}/edit`}
            startIcon={<EditIcon />}
          >
            Muokkaa harjoitusta
          </Button>
        )}
      </Box>

      {/* Exercise Configuration */}
      
    </Container>
  );
};

export default ExerciseViewPage;
