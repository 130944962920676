// src/components/Exercise/ExerciseCommonFields.tsx
import React from 'react';
import { Box, TextField } from '@mui/material';

interface ExerciseCommonFieldsProps {
  instructions: string;
  additionalInfo: string;
  order: number;
  maxScore: number;
  onChange: (field: string, value: any) => void;
}

const ExerciseCommonFields: React.FC<ExerciseCommonFieldsProps> = ({ instructions, additionalInfo, order, maxScore, onChange }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <Box mb={4}>
      <TextField
        label="Järjestys"
        name="order"
        type="number"
        value={order}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 3 }}
      />
      <TextField
        label="Ohjeet"
        name="instructions"
        value={instructions}
        onChange={handleInputChange}
        fullWidth
        multiline
        rows={2}
        sx={{ mb: 3 }}
      />
      <TextField
        label="Lisätiedot"
        name="additionalInfo"
        value={additionalInfo}
        onChange={handleInputChange}
        fullWidth
        multiline
        rows={2}
        sx={{ mb: 3 }}
      />      
      <TextField
        label="Maksimipisteet"
        name="maxScore"
        type="number"
        value={maxScore}
        onChange={handleInputChange}
        fullWidth
      />
    </Box>
  );
};

export default ExerciseCommonFields;
