import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { verifyPairingAnswers } from '../../../common/verifyPairing'; // Adjust path as necessary

interface PairingPreviewProps {
  config: {
    questions: {
      id: string;
      termA: { text: string; language: string };
      termBOptions: string[];
      termBLanguage: string;
      hint: string;
    }[];
    isVisual: boolean;
  };
}

interface QuestionPreviewProps {
  question: {
    id: string;
    termA: { text: string; language: string };
    termBOptions: string[];
    termBLanguage: string;
    hint: string;
  };
  isVisual: boolean;
  onAnswerChange: (questionId: string, answer: string) => void;
  isCorrect?: boolean;
}

const DraggableTermB: React.FC<{ text: string; id: string; onDrop: (text: string) => void }> = ({ text, id, onDrop }) => {
  const [, drag] = useDrag({
    type: 'termB',
    item: { id, text },
  });

  return (
    <Box ref={drag} p={1} border={1} borderColor="grey.300" borderRadius={2} m={1} style={{ cursor: 'pointer' }}>
      {text}
    </Box>
  );
};

const DroppableTermA: React.FC<{ current: string | null; onDrop: (text: string) => void }> = ({ current, onDrop }) => {
  const [, drop] = useDrop({
    accept: 'termB',
    drop: (item: { id: string; text: string }) => onDrop(item.text),
  });

  return (
    <Box ref={drop} p={1} border={1} borderColor="grey.500" borderRadius={2} m={1} minWidth="200px">
      {current || 'Drop here'}
    </Box>
  );
};

const QuestionPreview: React.FC<QuestionPreviewProps> = ({ question, isVisual, onAnswerChange, isCorrect }) => {
  const [currentAnswer, setCurrentAnswer] = useState<string | null>(null);

  const handleDrop = (text: string) => {
    setCurrentAnswer(text);
    onAnswerChange(question.id, text);
  };

  const handleAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const answer = e.target.value;
    setCurrentAnswer(answer);
    onAnswerChange(question.id, answer);
  };

  return (
    <Box mb={2} display="flex" flexDirection="column">
      <Typography variant="body1">
        {question.termA.text} ({question.termA.language}):
      </Typography>
      {isVisual ? (
        <Box display="flex" alignItems="center" mt={1}>
          <DroppableTermA current={currentAnswer} onDrop={handleDrop} />
          <Box display="flex" flexWrap="wrap" ml={2}>
            {question.termBOptions.map((option, index) => (
              <DraggableTermB key={`${question.id}-${index}`} id={`${question.id}-${index}`} text={option} onDrop={handleDrop} />
            ))}
          </Box>
        </Box>
      ) : (
        <TextField
          variant="outlined"
          size="small"
          value={currentAnswer || ''}
          onChange={handleAnswerChange}
          sx={{ mt: 1 }}
        />
      )}
      {isCorrect !== undefined && (
        <Box mt={1}>
          {isCorrect ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
        </Box>
      )}
    </Box>
  );
};

const PairingPreview: React.FC<PairingPreviewProps> = ({ config }) => {
  const [userAnswers, setUserAnswers] = useState<{ [key: string]: string }>({});
  const [results, setResults] = useState<{ questionId: string; isCorrect: boolean }[]>([]);

  const handleAnswerChange = (questionId: string, answer: string) => {
    setUserAnswers(prev => ({ ...prev, [questionId]: answer }));
  };

  const handleCheckAnswers = () => {
    const answersArray = Object.entries(userAnswers).map(([questionId, answer]) => {
      const question = config.questions.find(q => q.id === questionId);
      return {
        questionId,
        selectedTermB: { text: answer, language: question?.termBLanguage || '' },
      };
    });

    const verificationResults = verifyPairingAnswers(answersArray, {
      exerciseType: 'pairing',
      isVisual: config.isVisual,
      questions: config.questions.map(q => ({
        id: q.id,
        termA: q.termA,
        termBOptions: q.termBOptions,
        termBLanguage: q.termBLanguage,
        hint: q.hint,
      })),
    });

    setResults(verificationResults);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box mt={2}>
        {config.questions.map((question, index) => {
          const result = results.find(res => res.questionId === question.id);
          return (
            <QuestionPreview
              key={index}
              question={question}
              isVisual={config.isVisual}
              onAnswerChange={handleAnswerChange}
              isCorrect={result?.isCorrect}
            />
          );
        })}
        <Box mt={2}>
          <Button variant="contained" onClick={handleCheckAnswers}>
            Tarkista vastaukset
          </Button>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default PairingPreview;
