// Represents a container or box in the exercise
export interface Container {
  id: string;
  name: string;
}

// Represents a term to be sorted into a container
export interface Term {
  id: string;
  text: string;
  containerId: string; // Correct container ID
}

// Represents the exercise configuration
export interface SortToBoxesExerciseConfig {
  exerciseType: string; // e.g., 'sort_to_boxes'
  containers: Container[];
  terms: Term[]; // Each term includes the correct containerId
}

// Represents a user's answer for a term
export interface UserSortToBoxesAnswer {
  termId: string;
  assignedContainerId: string; // The container the student assigned the term to
}

// Represents the result of checking a user's answer for correctness
export interface SortToBoxesVerificationResult {
  termId: string;
  isCorrect: boolean;
}

export function verifySortToBoxesAnswers(
  userAnswers: UserSortToBoxesAnswer[],
  config: SortToBoxesExerciseConfig
): SortToBoxesVerificationResult[] {
  const results: SortToBoxesVerificationResult[] = [];

  // Loop through each term in the exercise config
  config.terms.forEach((term) => {
    // Find the user's assigned container for this term
    const userAnswer = userAnswers.find((ua) => ua.termId === term.id);

    if (!userAnswer) {
      // If the user didn't assign the term to any container, it's incorrect
      results.push({ termId: term.id, isCorrect: false });
      return;
    }

    // Check if the assigned container matches the correct container
    const isCorrect = userAnswer.assignedContainerId === term.containerId;

    results.push({ termId: term.id, isCorrect });
  });

  return results;
}
