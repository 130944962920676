import React from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectChangeEvent } from '@mui/material/Select';

interface Container {
  id: string;
  name: string;
}

interface Term {
  id: string;
  text: string;
  containerId: string;
}

interface SortToBoxesQuestionProps {
  index: number;
  term: Term;
  containers: Container[];
  onTermChange: (index: number, updatedTerm: Term) => void;
  onRemoveTerm: (index: number) => void;
}

const SortToBoxesQuestion: React.FC<SortToBoxesQuestionProps> = ({
  index,
  term,
  containers,
  onTermChange,
  onRemoveTerm,
}) => {
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onTermChange(index, { ...term, text: e.target.value });
  };

  const handleContainerChange = (event: SelectChangeEvent<string>) => {
    onTermChange(index, { ...term, containerId: event.target.value as string });
  };

  return (
    <Box display="flex" alignItems="center" mb={2}>
      <TextField
        label={`Termi ${index + 1}`}
        value={term.text}
        onChange={handleTextChange}
        sx={{ mr: 2 }}
      />
      <FormControl sx={{ mr: 2 }}>
        <InputLabel id={`container-select-label-${index}`}>Kontti</InputLabel>
        <Select
          labelId={`container-select-label-${index}`}
          value={term.containerId}
          label="Kontti"
          onChange={handleContainerChange}
        >
          <MenuItem value="">
            <em>Ei valittu</em>
          </MenuItem>
          {containers.map((container) => (
            <MenuItem key={container.id} value={container.id}>
              {container.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton aria-label="delete" onClick={() => onRemoveTerm(index)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default SortToBoxesQuestion;
