import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography, Box, Button, Paper, Avatar, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { User, Exercise, Assignment } from '../../../types/interfaces';
import Breadcrumb from '../../../components/Breadcrumb';
import { getExercise } from '../../../services/exerciseService';
import { getAllStudents } from '../../../services/userService';
import { getAssignmentsByExercise, updateAssignment, deleteAssignment, createAssignment } from '../../../services/assignmentService'; // Import update, delete, and create services
import { useAuth } from '../../../components/AuthContext';
import { AssignmentStatus } from '../../../types/enums';

const AssignExercisePage: React.FC = () => {
  const { courseId, lessonId, exerciseId } = useParams<{ courseId: string, lessonId: string, exerciseId: string }>();
  const navigate = useNavigate(); // For navigation after saving
  const { user } = useAuth();
  const [exercise, setExercise] = useState<Exercise | null>(null);
  const [availableStudents, setAvailableStudents] = useState<User[]>([]);
  const [assignedStudents, setAssignedStudents] = useState<User[]>([]);
  const [initialAssignedStudents, setInitialAssignedStudents] = useState<User[]>([]); // To track initial assignments

  // State for assignment fields
  const [dueDate, setDueDate] = useState<string>('');
  const [maxAttemptsAllowed, setMaxAttemptsAllowed] = useState<number | null>(100); // Default max attempts to 100
  const [isGradedManually, setIsGradedManually] = useState<boolean>(false);
  const [supplementalMaterial, setSupplementalMaterial] = useState<string>('');

  useEffect(() => {
    const fetchStudentsAndAssignments = async () => {
      try {
        const [studentsResponse, assignmentsResponse] = await Promise.all([
          getAllStudents(),
          exerciseId ? getAssignmentsByExercise(parseInt(exerciseId, 10)) : [],
        ]);

        const assignedStudentIds = assignmentsResponse.data.map((assignment: Assignment) => assignment.studentId);
        const assigned = studentsResponse.data.filter((student: User) => assignedStudentIds.includes(student.id));
        const available = studentsResponse.data.filter((student: User) => !assignedStudentIds.includes(student.id));

        setAvailableStudents(available);
        setAssignedStudents(assigned);
        setInitialAssignedStudents(assigned); // Set initial assigned students to track changes

        // Initialize assignment fields based on the first assignment loaded, if available
        if (assignmentsResponse.data.length > 0) {
          const firstAssignment = assignmentsResponse.data[0];
          setDueDate(firstAssignment.dueDate ? new Date(firstAssignment.dueDate).toISOString().split('T')[0] : '');
          setMaxAttemptsAllowed(firstAssignment.maxAttemptsAllowed || 100); // Default max attempts to 100 if not set
          setIsGradedManually(firstAssignment.isGradedManually || false);
          setSupplementalMaterial(firstAssignment.supplementalMaterial || '');
        } else {
          // If no assignments, set default values
          const defaultDueDate = new Date();
          defaultDueDate.setDate(defaultDueDate.getDate() + 7); // Set due date to 7 days from now
          setDueDate(defaultDueDate.toISOString().split('T')[0]);
          setMaxAttemptsAllowed(100);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    const fetchExercise = async () => {
      try {
        if (!lessonId || !exerciseId) return;
        const response = await getExercise(parseInt(lessonId, 10), parseInt(exerciseId, 10));
        setExercise(response.data);
      } catch (error) {
        console.error('Failed to fetch exercise:', error);
      }
    };

    fetchExercise();
    fetchStudentsAndAssignments();
  }, [lessonId, exerciseId]);

  const handleAssign = (student: User) => {
    setAvailableStudents(availableStudents.filter(s => s.id !== student.id));
    setAssignedStudents([...assignedStudents, student]);
  };

  const handleUnassign = (student: User) => {
    setAssignedStudents(assignedStudents.filter(s => s.id !== student.id));
    setAvailableStudents([...availableStudents, student]);
  };

  const handleSaveAssignments = async () => {
    try {
      // Handle creation and updates
      for (const student of assignedStudents) {
        const assignment: Assignment = {
          studentId: student.id,
          exerciseId: exerciseId ? parseInt(exerciseId, 10) : 0,
          assignerEmail: user?.email,
          status: AssignmentStatus.New,
          dueDate: dueDate ? new Date(dueDate) : null,
          maxAttemptsAllowed,
          isGradedManually,
          supplementalMaterial,
        };

        const wasInitiallyAssigned = initialAssignedStudents.some(s => s.id === student.id);

        if (wasInitiallyAssigned) {
          // Update existing assignment
          await updateAssignment(student.id, assignment);
        } else {
          // Create new assignment
          await createAssignment(assignment);
        }
      }

      // Handle deletions
      for (const initialStudent of initialAssignedStudents) {
        const isStillAssigned = assignedStudents.some(s => s.id === initialStudent.id);
        if (!isStillAssigned) {
          // Delete assignment for students who were initially assigned but have been removed
          await deleteAssignment(initialStudent.id, parseInt(exerciseId!, 10));
        }
      }

      // Navigate to the lesson view page after saving
      navigate(`/course/${courseId}/lesson/${lessonId}`);
    } catch (error) {
      console.error('Määräysten tallentaminen epäonnistui:', error);
    }
  };

  return (
    <Container>
      {courseId && <Breadcrumb courseId={courseId} lessonId={lessonId} exerciseId={exerciseId} additionalCrumb='Määrää tehtävä oppilaalle' />}
      <Box mt={3} mb={4}>
        <Typography variant="h4" gutterBottom>
          Määritä opiskelijat harjoitukseen
        </Typography>
      </Box>

      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Harjoitus: {exercise ? exercise.instructions : 'Ladataan...'}
        </Typography>
        <Typography variant="body1">
          Kuvaus: {exercise ? exercise.additionalInfo || 'Ei lisätietoja.' : 'Ladataan...'}
        </Typography>

        {/* New Fields for Assignment */}
        <Box mt={2}>
          <TextField
            label="Eräpäivä"
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 3 }}
          />
          <TextField
            label="Sallittujen yritysten enimmäismäärä"
            type="number"
            value={maxAttemptsAllowed || ''}
            onChange={(e) => setMaxAttemptsAllowed(parseInt(e.target.value))}
            fullWidth
            sx={{ mb: 3 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isGradedManually}
                onChange={(e) => setIsGradedManually(e.target.checked)}
              />
            }
            label="Arvioidaanko manuaalisesti?"
            sx={{ mb: 3 }}
          />
          <TextField
            label="Täydennysmateriaali"
            value={supplementalMaterial}
            onChange={(e) => setSupplementalMaterial(e.target.value)}
            fullWidth
            multiline
            rows={3}
          />
        </Box>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>Vapaat opiskelijat</Typography>
          <Box>
            {availableStudents.map(student => (
              <Card 
                key={student.id} 
                sx={{ mb: 2, cursor: 'pointer' }}
                onClick={() => handleAssign(student)}
              >
                <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar alt={student.firstName + ' ' + student.lastName} src={student.picture} sx={{ marginRight: 2 }} />
                  <Box>
                    <Typography variant="h6">{student.firstName} {student.lastName}</Typography>
                    <Typography variant="body2" color="textSecondary">{student.group}</Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>Määritetyt opiskelijat</Typography>
          <Box>
            {assignedStudents.map(student => (
              <Card 
                key={student.id} 
                sx={{ mb: 2, cursor: 'pointer' }}
                onClick={() => handleUnassign(student)}
              >
                <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar alt={student.firstName + ' ' + student.lastName} src={student.picture} sx={{ marginRight: 2 }} />
                  <Box>
                    <Typography variant="h6">{student.firstName} {student.lastName}</Typography>
                    <Typography variant="body2" color="textSecondary">{student.group}</Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleSaveAssignments}>
          Tallenna määräykset
        </Button>
      </Box>
    </Container>
  );
};

export default AssignExercisePage;
