import React, { useState, useEffect } from 'react';
import { TextField, SxProps } from '@mui/material';

interface FinnishNumberInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  sx?: SxProps; // Accept sx prop for styling
}

const FinnishNumberInput: React.FC<FinnishNumberInputProps> = ({ value, onChange, disabled = false, sx }) => {
  const [formattedValue, setFormattedValue] = useState<string>('');

  // Function to format the number as Finnish format with spaces as thousands separators
  const formatFinnishNumber = (input: string): string => {
    // Remove any non-digit characters except spaces
    if(!input) return '';
    const digitsOnly = input.replace(/\s+/g, '');

    // Add spaces as thousand separators
    return digitsOnly.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  // Update formatted value on input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const formatted = formatFinnishNumber(rawValue);
    setFormattedValue(formatted);
    onChange(formatted); // Pass the formatted value to the parent component
  };

  // Update the formatted value if the parent changes the value prop
  useEffect(() => {
    setFormattedValue(formatFinnishNumber(value));
  }, [value]);

  return (
    <TextField
      variant="outlined"
      value={formattedValue}
      onChange={handleChange}
      disabled={disabled}
      sx={sx} // Pass the sx prop to the TextField
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9 ]*',
      }}
    />
  );
};

export default FinnishNumberInput;
