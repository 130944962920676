// Define the Question and ExerciseConfig interfaces with the added voiceCode

export interface Question {
  id: string;
  text: string;
  answers: string[][]; // Array of arrays of acceptable answers
  aiCheckEnabled: boolean; // Placeholder if AI check is implemented in future
  hint: string;
  voiceCode: string; // New property for specifying the voice code for each question
}

export interface ExerciseConfig {
  exerciseType: string;
  language: string;
  isContinuous: boolean;
  questions: Question[]; // Each question can now have a specific voiceCode
}

interface UserAnswer {
  questionId: string;
  answer: string;
}

interface VerificationResult {
  questionId: string;
  isCorrect: boolean;
}

export function verifyFillInBlanks(userAnswers: UserAnswer[], config: ExerciseConfig): VerificationResult[] {
  const results: VerificationResult[] = [];
  const userAnswerMap = new Map(userAnswers.map(answer => [answer.questionId, answer.answer.trim()]));

  for (const question of config.questions) {
    const userAnswer = userAnswerMap.get(question.id);

    if (!userAnswer) {
      results.push({ questionId: question.id, isCorrect: false });
      continue;
    }

    // Split user answer by commas for multiple blanks
    const userAnswersArray = userAnswer.split(',').map(ans => ans.trim().toLowerCase());

    const isCorrect = question.answers.some(acceptableAnswer => {
      // Check if each part of the acceptable answer matches the user's answer
      return acceptableAnswer.every((ans, index) => ans.trim().toLowerCase() === userAnswersArray[index]);
    });

    results.push({ questionId: question.id, isCorrect });
  }

  return results;
}
