import React from 'react';
import { Box, TextField, Typography, IconButton, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface QuestionProps {
  question: {
    id: string;
    termA: { text: string; language: string };  // Term A with text and language
    termBOptions: string[];  // Array of correct answers as strings
    termBLanguage: string;  // Single language for all term B options
    hint: string;
  };
  index: number;
  onQuestionChange: (index: number, updatedQuestion: any) => void;
  onRemoveQuestion: (index: number) => void;
  isVisual: boolean;
}

const PairingQuestion: React.FC<QuestionProps> = ({ question, index, onQuestionChange, onRemoveQuestion, isVisual }) => {
  const handleTermATextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onQuestionChange(index, { ...question, termA: { ...question.termA, text: e.target.value } });
  };

  const handleTermALanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onQuestionChange(index, { ...question, termA: { ...question.termA, language: e.target.value } });
  };

  const handleTermBOptionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const options = e.target.value.split('|').map(opt => opt.trim());
    onQuestionChange(index, { ...question, termBOptions: options });
  };

  const handleTermBLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onQuestionChange(index, { ...question, termBLanguage: e.target.value });
  };

  const handleHintChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onQuestionChange(index, { ...question, hint: e.target.value });
  };

  return (
    <Box mb={3} p={2} border={1} borderColor="grey.300" borderRadius={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Question {index + 1}</Typography>
        <IconButton onClick={() => onRemoveQuestion(index)} color="secondary">
          <DeleteIcon />
        </IconButton>
      </Box>

      <Divider sx={{ my: 2 }} />

      <TextField
        label="Term A Text"
        variant="outlined"
        fullWidth
        value={question.termA.text}
        onChange={handleTermATextChange}
        margin="normal"
      />

      <TextField
        label="Language of Term A"
        variant="outlined"
        fullWidth
        value={question.termA.language}
        onChange={handleTermALanguageChange}
        margin="normal"
      />

      <TextField
        label="Term B Options (Separate by |)"
        variant="outlined"
        fullWidth
        placeholder="e.g., virus | cell | bacteria"
        value={question.termBOptions.join('|')}
        onChange={handleTermBOptionsChange}
        margin="normal"
      />

      <TextField
        label="Language of Term B Options"
        variant="outlined"
        fullWidth
        value={question.termBLanguage}
        onChange={handleTermBLanguageChange}
        margin="normal"
      />

      <TextField
        label="Hint"
        variant="outlined"
        fullWidth
        value={question.hint}
        onChange={handleHintChange}
        margin="normal"
      />
    </Box>
  );
};

export default PairingQuestion;
