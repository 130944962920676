import React from 'react';
import { Lesson } from '../../types/interfaces';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EventIcon from '@mui/icons-material/Event';

interface LessonDetailsProps {
  lesson: Lesson;
}

const LessonDetails: React.FC<LessonDetailsProps> = ({ lesson }) => {
  return (
    <Card sx={{ p: 2, mb: 3, backgroundColor: '#f9f9f9' }}>
      <CardContent>
        {/* Lesson Title */}
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
          {lesson.title}
        </Typography>

        {/* Divider */}
        <Divider sx={{ my: 2 }} />

        {/* Lesson Description */}
        <Box display="flex" alignItems="center" mb={2}>
          <DescriptionIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Kuvaus</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {lesson.description}
        </Typography>

        {/* Lesson Order */}
        <Box display="flex" alignItems="center" mb={2}>
          <FormatListNumberedIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Järjestys</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {lesson.order}
        </Typography>

        {/* Lesson Status */}
        <Box display="flex" alignItems="center" mb={2}>
          <CheckCircleIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Tila</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {lesson.isActive ? 'Aktiivinen' : 'Ei aktiivinen'}
        </Typography>

        {/* Lesson Creation Date */}
        <Box display="flex" alignItems="center" mb={2}>
          <EventIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Luotu</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {new Date(lesson.createdAt).toLocaleDateString()}
        </Typography>

        {/* Lesson Update Date */}
        <Box display="flex" alignItems="center" mb={2}>
          <EventIcon sx={{ mr: 1, color: '#3f51b5' }} />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Päivitetty</Typography>
        </Box>
        <Typography variant="body1" paragraph>
          {new Date(lesson.updatedAt).toLocaleDateString()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default LessonDetails;
