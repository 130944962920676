import { evaluate } from 'mathjs';

export interface Question {
  operand1: number | null;
  operand2: number | null;
  operator: '+' | '-' | '*' | '/';
  randomizeOperands: boolean;
  randomizeAll: boolean;
  digitCount: number;
}

export interface AnswerVerificationResult {
  isCorrect: boolean;
  correctAnswer: number;
}

export const verifyMathArit2Operand1Phase = (question: Question, studentAnswer: string): AnswerVerificationResult => {
  const { operand1, operand2, operator } = question;

  if (operand1 === null || operand2 === null) {
    return { isCorrect: false, correctAnswer: NaN };
  }

  // Construct the expression as a string
  const expression = `${operand1} ${operator} ${operand2}`;

  // Use mathjs to safely evaluate the expression
  let correctAnswer: number;
  try {
    correctAnswer = evaluate(expression);
  } catch (error) {
    console.error('Error evaluating expression:', error);
    correctAnswer = NaN; // Fallback in case of evaluation error
  }

  return {
    isCorrect: parseFloat(studentAnswer) === correctAnswer,
    correctAnswer,
  };
};
