import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  SortToBoxesExerciseConfig,
  UserSortToBoxesAnswer,
  verifySortToBoxesAnswers,
  SortToBoxesVerificationResult,
} from '../../../common/verifySortToBoxes'; // Adjust the import path accordingly

interface Container {
  id: string;
  name: string;
}

interface Term {
  id: string;
  text: string;
  containerId: string; // Correct containerId
}

interface SortToBoxesStudentProps {
  config: {
    containers: Container[];
    terms: Term[];
  };
  onAnswerChange: (updatedTerms: Term[]) => void;
  initialAssignedTerms: Term[];
  assignmentStatus: 'New' | 'Active' | 'InReview';
  hasSubmitted: boolean;
  feedback: SortToBoxesVerificationResult[] | null;
  disabled: boolean; // Added this line
}

const DraggableTerm: React.FC<{ term: Term; feedbackItem?: SortToBoxesVerificationResult }> = ({
  term,
  feedbackItem,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TERM',
    item: { id: term.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Paper
      ref={drag}
      sx={{
        padding: '8px',
        margin: '8px',
        width: '100px',
        textAlign: 'center',
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        position: 'relative',
      }}
      elevation={2}
    >
      {term.text}
      {feedbackItem && (
        <Box
          sx={{
            position: 'absolute',
            top: -5,
            right: -5,
          }}
        >
          {feedbackItem.isCorrect ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </Box>
      )}
    </Paper>
  );
};

interface DroppableContainerProps {
  container: Container;
  terms: Term[];
  onDrop: (termId: string) => void;
  feedback: SortToBoxesVerificationResult[] | null;
  shouldShowFeedback: boolean;
}

const DroppableContainer: React.FC<DroppableContainerProps> = ({
  container,
  terms,
  onDrop,
  feedback,
  shouldShowFeedback,
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'TERM',
    drop: (item: { id: string }) => {
      onDrop(item.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <Paper
      ref={drop}
      sx={{
        width: '200px',
        minHeight: '150px',
        margin: '16px',
        padding: '8px',
        backgroundColor: isOver ? 'lightgreen' : 'white',
      }}
      elevation={3}
    >
      <Typography variant="h6" align="center">
        {container.name}
      </Typography>
      {terms.map((term) => {
        const feedbackItem = feedback?.find((f) => f.termId === term.id);
        return (
          <DraggableTerm
            key={term.id}
            term={term}
            feedbackItem={shouldShowFeedback ? feedbackItem : undefined}
          />
        );
      })}
    </Paper>
  );
};

const SortToBoxesStudent: React.FC<SortToBoxesStudentProps> = ({
  config,
  onAnswerChange,
  initialAssignedTerms,
  assignmentStatus,
  hasSubmitted,
  feedback: externalFeedback,
  disabled,
}) => {
  const { containers, terms } = config;
  const [assignedTerms, setAssignedTerms] = useState<Term[]>(
    terms.map((term) => ({ ...term, containerId: '' }))
  );
  const [feedback, setFeedback] = useState<SortToBoxesVerificationResult[]>([]);

  const handleDrop = (containerId: string, termId: string) => {
    setAssignedTerms((prevTerms) =>
      prevTerms.map((term) => (term.id === termId ? { ...term, containerId } : term))
    );
  };

  const handleReset = () => {
    setAssignedTerms(terms.map((term) => ({ ...term, containerId: '' })));
    setFeedback([]);
  };

  // Group terms by their containerId
  const termsByContainer: { [key: string]: Term[] } = {};
  assignedTerms.forEach((term) => {
    if (!termsByContainer[term.containerId]) {
      termsByContainer[term.containerId] = [];
    }
    termsByContainer[term.containerId].push(term);
  });

  // Terms that are not assigned to any container
  const unassignedTerms = assignedTerms.filter((term) => !term.containerId);

  const shouldShowFeedback =
    assignmentStatus === 'InReview' || hasSubmitted || feedback.length > 0;

  useEffect(() => {
    const shouldCalculateFeedback =
      assignmentStatus === 'InReview' || hasSubmitted || feedback.length > 0;

    if (shouldCalculateFeedback && assignedTerms.length) {
      const userAnswers: UserSortToBoxesAnswer[] = assignedTerms.map((term) => ({
        termId: term.id,
        assignedContainerId: term.containerId || '',
      }));

      const exerciseConfig: SortToBoxesExerciseConfig = {
        exerciseType: 'sort_to_boxes', // Define the exercise type
        containers: config.containers,
        terms: config.terms,
      };

      const verificationResults = verifySortToBoxesAnswers(userAnswers, exerciseConfig);
      setFeedback(verificationResults);
    } else if (!shouldCalculateFeedback) {
      setFeedback([]); // Clear feedback when not in review or submitted
    }
  }, [
    assignedTerms,
    assignmentStatus,
    hasSubmitted,
    config,
    feedback.length,
  ]);

  useEffect(() => {
    onAnswerChange(assignedTerms);
  }, [assignedTerms, onAnswerChange]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Box mt={2}>
        <Box display="flex" flexWrap="wrap" justifyContent="center" mb={4}>
          {containers.map((container) => (
            <DroppableContainer
              key={container.id}
              container={container}
              terms={termsByContainer[container.id] || []}
              onDrop={(termId) => handleDrop(container.id, termId)}
              feedback={feedback}
              shouldShowFeedback={shouldShowFeedback}
            />
          ))}
        </Box>
        <Box>
          <Typography variant="subtitle1">Termit:</Typography>
          <Box display="flex" flexWrap="wrap">
            {unassignedTerms.map((term) => {
              const feedbackItem = feedback?.find((f) => f.termId === term.id);
              return (
                <DraggableTerm
                  key={term.id}
                  term={term}
                  feedbackItem={shouldShowFeedback ? feedbackItem : undefined}
                />
              );
            })}
          </Box>
        </Box>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleReset}
          sx={{ mt: 2 }}
          disabled={disabled}
        >
          Nollaa tehtävä
        </Button>
      </Box>
    </DndProvider>
  );
};

export default SortToBoxesStudent;
