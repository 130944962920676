// src/pages/Curriculums/CurriculumViewPage.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CurriculumDetails from '../../components/Curriculum/CurriculumDetails';
import { getCurriculum } from '../../services/curriculumService';
import { Curriculum } from '../../types/interfaces';
import { Container, CircularProgress, Typography } from '@mui/material';

const CurriculumViewPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [curriculum, setCurriculum] = useState<Curriculum | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await getCurriculum(parseInt(id, 10));
        setCurriculum(response.data);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!curriculum) {
    return (
      <Container>
        <Typography variant="h5" component="div">
          Opinto-ohjelmaa ei löytynyt
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <CurriculumDetails curriculum={curriculum} />
    </Container>
  );
};

export default CurriculumViewPage;
