import React from 'react';
import Login from '../components/Login';
import { Card, CardContent, CardHeader, Box, Typography } from '@mui/material';
import { useAuth } from '../components/AuthContext';
import LatestAssignments from '../components/Assignment/LatestAssignments';
import { UserRole } from '../common/enums';

const Home: React.FC = () => {
  console.log('Render home')
  const user = useAuth();

  return (
    <div>
      <Box p={3}>
        <Typography variant="h2">TutorTrack</Typography>
        {user.user ? (
          <>
            <Box mb={3}>
              <Card>
                <CardHeader
                  title="Tervetuloa"
                  sx={{ backgroundColor: '#d5d5ff' }}
                />
                <CardContent>
                  <Typography variant="body1" gutterBottom>
                    Olet kirjautunut sisään.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
            {user.user.role === UserRole.Student && (
              <LatestAssignments /> 
            )}
          </>
        ) : (
          <Box mb={3}>
            <Card>
              <CardHeader
                title="Tervetuloa"
                sx={{ backgroundColor: '#f5f5f5' }}
              />
              <CardContent>
                <Typography variant="body1" gutterBottom>
                  Tervetuloa Tutor Trackiin! Kirjaudu sisään aloittaaksesi.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Jos olet opiskelija, voit rekisteröityä normaalisti
                  käyttämällä Google-tiliäsi.
                </Typography>
                <Typography variant="body2">
                  Jos olet ohjaaja, rekisteröidy Google-tililläsi ja ota
                  yhteyttä ylläpitäjään saadaksesi ohjaajan oikeudet.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
        <Box>
          <Login />
        </Box>
      </Box>
    </div>
  );
};

export default Home;
