// Represents a user's answer to a pairing question
export interface UserPairingAnswer {
  questionId: string;
  selectedTermB: {
    text: string;
    language: string;
  };
}

// Represents the result of checking a user's answer for correctness
export interface PairingVerificationResult {
  questionId: string;
  isCorrect: boolean;
}

export interface PairingExerciseConfig {
  exerciseType: string;   // e.g., 'pairing'
  isVisual: boolean;      // True for visual drag-and-drop, false for text input
  questions: {
    id: string;
    termA: { text: string; language: string };  // Term A with text and language
    termBOptions: string[];  // Array of possible correct answers as strings
    termBLanguage: string;   // Language for all term B options
    hint: string;            // Optional hint for each question
  }[];
}


export function verifyPairingAnswers(
  userAnswers: UserPairingAnswer[],
  config: PairingExerciseConfig
): PairingVerificationResult[] {
  const results: PairingVerificationResult[] = [];
  
  // Loop through questions in config and use index to match userAnswers
  config.questions.forEach((question, index) => {
    const userAnswer = userAnswers[index]?.selectedTermB;

    if (!userAnswer) {
      results.push({ questionId: question.id, isCorrect: false });
      return; // Move to the next question if no answer is found
    }


    // Verify if user answer matches any of the correct options and termBLanguage
    const isCorrect = question.termBOptions.some(correctOption => {
      return (
        correctOption.trim().toLowerCase() === userAnswer.text.trim().toLowerCase() &&
        question.termBLanguage === userAnswer.language
      );
    });

    results.push({ questionId: question.id, isCorrect });
  });

  return results;
}

